import { Box, Grid, Typography } from "@mui/material";
const NoRecords = () => {
  return (
    <Grid item md={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "12rem",
        }}
      >
        <Typography variant="h4">No Records Found</Typography>
      </Box>
    </Grid>
  );
};
export default NoRecords;
