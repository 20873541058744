import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { useState ,useEffect} from "react";

const ToggleSwitch = ({ isChecked, toggleStatusHandler }) => {
    //console.log('isChecked', isChecked)
  const [checkedFlag, setCheckedFlag] = useState(isChecked);
   //console.log("checkedFlag", checkedFlag);
  const handleChangeStatus = (e) => {
    console.log("toggle button", e.target.checked);
    //setCheckedFlag(!checkedFlag);
    if (e.target.checked) {
      toggleStatusHandler(1);
    } else {
      toggleStatusHandler(3);
    }
  };
  useEffect(() => {
      setCheckedFlag(isChecked)
  }, [isChecked])
  return (
    <FormControl>
      <FormGroup>
        <FormControlLabel
          label={isChecked ? "ACTIVE" : "INACTIVE"}
          control={
            <Switch
              size="medium"
              checked={checkedFlag}
              onChange={handleChangeStatus}
            />
          }
        />
      </FormGroup>
    </FormControl>
  );
};
export default ToggleSwitch;
