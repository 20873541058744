import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { DownloadIcon } from "../../../assets/icons";
import Calendar from "./Calendar";
import {
  useGetInsuranceOptionsQuery,
  useGetMtypeOptionsQuery,
  useGetParentCatOptionsQuery,
  useLazyDownloadFilteredReportQuery,
  useLazyGetChildCatOptionsQuery,
} from "../../../apis/report/sessionRepApi";
import { useGetAllLocationQuery } from "../../../apis/location/locationApi";
import CustomSelect from "./CustomSelect";
import { useEffect, useState } from "react";
import moment from "moment";
import downloadExcel from "./downloadExcel";

const genderOptions = [
  {
    _id: 122,
    title: "Male",
  },
  {
    _id: 124,
    title: "Female",
  },
];
const FilteredReport = () => {
  // localState
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [parentCat, setParentCat] = useState([]);
  const [location, setLocation] = useState([]);
  const [childCat, setChildCat] = useState([]);
  const [gender, setGender] = useState([]);
  const [mType, setMtype] = useState([]);
  const [insurance, setInsurance] = useState([]);
  const [isVirtuallyJoined, setIsVirtuallyJoined] = useState("");
  const [inNy, setInNy] = useState("");
  const [childCatOptions, setChildCatOptions] = useState([]);
  const [isDisable, setIsDisable] = useState(true);

  // remoteState
  const {
    data: insuranceOptions,
    isFetching: isFetchingIn,
    isLoading: isLoadingIn,
  } = useGetInsuranceOptionsQuery();
  const {
    data: mTypeOptions,
    isFetching: isFetchingMt,
    isLoading: isLoadingMt,
  } = useGetMtypeOptionsQuery();
  const {
    data: parentCatOptions,
    isFetching: isFetchingPc,
    isLoading: isLoadingPc,
  } = useGetParentCatOptionsQuery();

  const {
    data: locationOptions,
    isFetching: isFetchingLo,
    isLoading: isLoadingLo,
  } = useGetAllLocationQuery({ page: 1, limit: 10 });

  const [getChildCat] = useLazyGetChildCatOptionsQuery();
  const [downloadFilteredReport, { isLoading, isFetching }] =
    useLazyDownloadFilteredReportQuery();

  //handlers
  const handleChange = (e) => {
    const value = e.target.value;
    setIsDisable(false);
    if (e.target.name == "parentCat") {
      setParentCat(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
    if (e.target.name == "childCat") {
      setChildCat(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
    if (e.target.name == "location") {
      setLocation(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }

    if (e.target.name == "mType") {
      setMtype(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }

    if (e.target.name == "insurance") {
      setInsurance(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }

    if (e.target.name == "gender") {
      setGender(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
    if (e.target.name == "onsite") {
      setIsVirtuallyJoined(value);
    }
    if (e.target.name == "ny") {
      setInNy(value);
    }
  };

  const handleDownload = async () => {
    const sDate = startDate?._d
      ? moment(startDate?._d).startOf("day").format("X") * 1
      : moment().startOf("day").format("X") * 1;
    const eDate = endDate?._d
      ? moment(endDate?._d).endOf("day").format("X") * 1
      : moment().endOf("day").format("X") * 1;
    const finalData = [];
    try {
      const resp = await downloadFilteredReport({
        sDate,
        eDate,
        insurance,
        mType,
        gender,
        inNy,
        isVirtuallyJoined,
        location,
        parentCat,
        childCat,
      }).unwrap();
      for (let el of resp?.events) {
        const {
          Date,
          startTime,
          endTime,
          topic,
          isVirtuallyAllowed,
          attendees,
          locationId,
          leaderId,
          categoryId,
        } = el;

        if (attendees.length > 0) {
          for (let m of attendees) {
            finalData.push({
              Date: Date,
              startTime: startTime,
              endTime: endTime,
              location: locationId?.name,
              parentCat: categoryId?.parentId?.title,
              childCat: categoryId?.title,
              topic: topic,
              leader: `${leaderId?.fName} ${leaderId?.lName}`,
              isVirtuallyAllowed: isVirtuallyAllowed ? "YES" : "NO",
              attendence: m?.isVirtuallyJoined ? "Virtual" : "On Site",
              inNy: m?.inNy ? "YES" : "NO",
              joinTime: m?.joinTime,
              udid: m?.memberId?.uid,
              fName: m?.memberId?.fName,
              lName: m?.memberId?.lName,
              doa: m?.memberId?.doaCareNumber,
              mType: m?.memberId?.mType,
              phone: m?.memberId?.phone,
              email: m?.memberId?.email,
              gender: m?.memberId?.gender,
              zip: m?.memberId?.zipCode,
              insurance: m?.memberId?.insurance,
              totalRec: resp?.totalRec,
            });
          }
        } else {
          finalData.push({
            Date: Date,
            startTime: startTime,
            endTime: endTime,
            location: locationId?.name,
            parentCat: categoryId?.parentId?.title,
            childCat: categoryId?.title,
            topic: topic,
            leader: `${leaderId?.fName} ${leaderId?.lName}`,
            isVirtuallyAllowed: isVirtuallyAllowed ? "YES" : "NO",
            attendence: "",
            inNy: "",
            joinTime: "",
            udid: "",
            fName: "",
            lName: "",
            doa: "",
            mType: "",
            phone: "",
            email: "",
            gender: "",
            zip: "",
            insurance: "",
            totalRec: resp?.totalRec,
          });
        }
      }
      //   reset all filters
      setStartDate("");
      setEndDate("");
      setParentCat([]);
      setLocation([]);
      setChildCat([]);
      setGender([]);
      setMtype([]);
      setInsurance([]);
      setIsVirtuallyJoined("");
      setInNy("");
      setIsDisable(true);
      // Reload the browser window
      window.location.reload();

      downloadExcel(finalData, "IH Session Report -Filtered");
    } catch (err) {
      console.log("API ERR--->", err);
    }
  };

  // useEffect
  useEffect(() => {
    if (parentCat.length > 0) {
      const parentIds = parentCat.join(",");
      (async function () {
        try {
          const resp = await getChildCat(parentIds).unwrap();
          //console.log("child cat api resp---->", resp?.childCats);
          setChildCatOptions(resp?.childCats);
        } catch (err) {
          console.log("API ERR WHILE FETCHING CHILD CAT---", err);
        }
      })();
    }
  }, [parentCat, getChildCat]);
  return (
    <>
      <Grid item md={12} sx={{ mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            gap: "4rem",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            Download Filtered Sessions Reports
          </Typography>
          <LoadingButton
            sx={{ alignSelf: "center" }}
            disabled={isDisable}
            startIcon={<DownloadIcon />}
            variant="contained"
            size="medium"
            loading={isLoading || isFetching}
            onClick={handleDownload}
          >
            CSV
          </LoadingButton>
        </Box>
      </Grid>
      <Grid item md={12} container spacing={2}>
        <Grid item md={2}>
          <Calendar
            label="Start Date"
            setFieldValue={setStartDate}
            setIsDisable={setIsDisable}
          />
        </Grid>
        <Grid item md={2}>
          <Calendar
            label="End Date"
            setFieldValue={setEndDate}
            setIsDisable={setIsDisable}
          />
        </Grid>
        {/* parent Cat */}
        <Grid item md={2}>
          <CustomSelect
            name="parentCat"
            label="Parent Category"
            selectedValue={parentCat}
            options={
              isFetchingPc || isLoadingPc ? [] : parentCatOptions?.parentCats
            }
            handleChange={handleChange}
            isIdToUseInValue={true}
          />
        </Grid>
        {/* child Cat */}
        <Grid item md={2}>
          <CustomSelect
            name="childCat"
            label="Child Category"
            selectedValue={childCat}
            options={childCatOptions}
            handleChange={handleChange}
            isIdToUseInValue={true}
          />
        </Grid>
        {/* location */}
        <Grid item md={2}>
          <CustomSelect
            name="location"
            label="Location"
            selectedValue={location}
            options={
              isFetchingLo || isLoadingLo ? [] : locationOptions?.locations
            }
            handleChange={handleChange}
            isIdToUseInValue={true}
          />
        </Grid>
      </Grid>
      <Grid item md={12} container spacing={2}>
        {/* mtype */}
        <Grid item md={2}>
          <CustomSelect
            name="mType"
            label="Membership Type"
            selectedValue={mType}
            options={isFetchingMt || isLoadingMt ? [] : mTypeOptions?.mType}
            handleChange={handleChange}
            isIdToUseInValue={false}
          />
        </Grid>
        {/* insurance */}
        <Grid item md={2}>
          <CustomSelect
            name="insurance"
            label="Insurance"
            selectedValue={insurance}
            options={
              isFetchingIn || isLoadingIn ? [] : insuranceOptions?.insurance
            }
            handleChange={handleChange}
            isIdToUseInValue={false}
          />
        </Grid>
        {/* gender */}
        <Grid item md={2}>
          <CustomSelect
            name="gender"
            label="Gender"
            selectedValue={gender}
            options={genderOptions}
            handleChange={handleChange}
            isIdToUseInValue={false}
          />
        </Grid>
        {/* onSite/virtual */}
        <Grid item md={2}>
          <TextField
            label="On Site/Virtual"
            select
            fullWidth
            name="onsite"
            value={isVirtuallyJoined}
            onChange={handleChange}
          >
            <MenuItem value={false}>On Site</MenuItem>
            <MenuItem value={true}>Virtual</MenuItem>
          </TextField>
        </Grid>
        {/* inNy/Out */}
        <Grid item md={2}>
          <TextField
            label="In NY/Out"
            select
            fullWidth
            name="ny"
            value={inNy}
            onChange={handleChange}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </TextField>
        </Grid>
      </Grid>
    </>
  );
};

export default FilteredReport;
