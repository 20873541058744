import { Navigate } from "react-router-dom";
import { getTokenFromLocalStorage } from "../utils/localStorage";

const ProtectedRoute = ({ children }) => {
  const token = getTokenFromLocalStorage();
  if (!token) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default ProtectedRoute;
