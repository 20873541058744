import DeleteIconButton from "../../components/forms/DeleteIconButton";
import { useRemoveAttendessMutation } from "../../apis/event/eventApi";

const CustomDelete = ({ sessionId, attendeeId }) => {
  const [removeAttendee, { isLoading }] = useRemoveAttendessMutation();

  const deleteHandler = async () => {
    const deleteData = {
      attendeeId: attendeeId,
    };
    try {
      const resp = await removeAttendee({ sessionId, ...deleteData }).unwrap();
      //console.log("resp---", resp);
      //  control is not reaching after delete
    } catch (err) {
      console.log("remove attendee from event api err---", err);
    }
  };

  return (
    <DeleteIconButton isLoading={isLoading} deleteHandler={deleteHandler} />
  );
};

export default CustomDelete;
