import { Fab, Tooltip } from "@mui/material";
import { AddIcon } from "./../../assets/icons/index";

const FloatingButton = ({ buttonName, setIsModalOpen }) => {
  return (
    <Tooltip title={buttonName}>
      <Fab
        size="large"
        color="primary"
        sx={{
          margin: 0,
          top: "auto",
          right: 60,
          bottom: 60,
          left: "auto",
          position: "fixed",
        }}
        onClick={() => {
          //console.log("cliekc");
          setIsModalOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
    </Tooltip>
  );
};
export default FloatingButton;
