import { MenuItem, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

const CustomSelectWrapper = ({
  name,
  options,
  isFetchingState,
  setStateId,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    //console.log("from custom select wrapper--->", e.target.value);
    if (isFetchingState) {
      const getState = options.find((option) => {
        return option.value == e.target.value;
      });
      //console.log("getId", getState.id);
      setStateId(getState.id);
    }

    setFieldValue(name, e.target.value);
  };
  const config = {
    ...field,
    ...rest,
    onChange: handleChange,
  };
  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <TextField {...config}>
      {options.map((el, index) => {
        return (
          <MenuItem key={`${el.id}${index}`} value={el.value}>
            {el.value}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default CustomSelectWrapper;
