import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

const TextFieldDropDown = ({ name, label, options }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  };
  const handleOtherOptionChange = (e) => {
    setFieldValue(name, e.target.value);
  };
  const config = {
    ...field,
    input: <OutlinedInput label={label} />,
    onChange: handleChange,
    renderValue: (selected) => selected,
    MenuProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    },
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
      <Select {...config}>
        {options.map((option, index) => {
          return (
            <MenuItem key={`${option._id}${option.index}`} value={option._id}>
              {option.title}
            </MenuItem>
          );
        })}
        <TextField
          label="Other"
          variant="outlined"
          size="small"
          onChange={handleOtherOptionChange}
          sx={{ width: "80%", marginLeft: 2 }}
        />
      </Select>
    </FormControl>
  );
};

export default TextFieldDropDown;
