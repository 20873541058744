import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const downloadExcel = (data, sheetName) => {
  const customHeaders = {
    uid: "IHUDID",
    doaCareNumber: "DoA Care Number",
    fName: "First Name",
    lName: "Last Name",
    dob: "DOB",
    gender: "Gender",
    phone: "Phone Number",
    email: "Email Id",
    zipCode: "Zip Code",
    apartmentNo: "Apartment No",
    street: "Street Address",
    city: "City",
    state: "State",
    country: "Country",
    comments: "Additional Comments",
    maritalStatus: "Marital Status",
    mType: "Membership Type",
    employment: "Employment",
    insurance: "Health Insurance",
    language: "Languages Spoken",
    education: "Education",
    living: "Living Arrangements",
    physicianName: "Physician Name",
    physicianPhone: "Physician Phone",
    c1Name: "Emergency Contact1 Name",
    c1Phone: "Emergency Contact1 Phone",
    c1Relationship: "Emergency Contact1 Relationship",
    c2Name: "Contact2 Name",
    c2Phone: "Contact2 Phone",
    c2Relationship: "Contact2 Relationship",
    medicalNotes: "Medical Condition Notes",
  };

  // Map the data to align with custom headers
  const formattedData = data.map((item) => {
    const formattedItem = {};
    for (const key in item) {
      if (customHeaders[key]) {
        formattedItem[customHeaders[key]] = item[key];
      }
    }
    return formattedItem;
  });
  // Create a new workbook
  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.json_to_sheet(formattedData);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, sheet, sheetName || "Sheet1");

  // Generate the XLSX file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Convert the buffer to a Blob and save the file
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${sheetName || "exported_data"}.xlsx`);
};

export default downloadExcel;
