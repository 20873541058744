const { Container, Box, Typography } = require("@mui/material");

const Privacy = () => {
  return (
    <Container maxWidth="sm" sx={{ paddingTop: "1rem" }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Typography variant="h6">
          <b>1.Information We Collect:</b> We collect the following personal
          information from senior citizens: First name Last name Phone number
          Email address Date of birth Address Medical condition Education
        </Typography>
        <Typography variant="h6">
          <b>2.Purpose of Data Collection:</b> The personal information
          collected is used for the following purposes: Authenticating user
          accounts through OTP(One-Time Password) sent to the provided phone
          number or email address. Providing personalized services tailored to
          the user's preferences and needs. Contacting users for support
          notifications,and important updates related to the website and
          services.
        </Typography>
        <Typography variant="h6">
          <b>3.Data Security:</b>We take appropriate security measures to
          protect the personal information provided by senior citizens. We use
          industry-standard encryption, firewalls, and secure servers to
          safeguard the data against unauthorized access, alteration,or
          disclosure.
        </Typography>
        <Typography variant="h6">
          <b>4.Updates to Privacy Policy:</b>We may update this privacy policy
          from time to time. Any changes will be posted on our website, and the
          revised policy will be effective upon posting. It is advisable to
          review this policy periodically for any updates.
        </Typography>
        <Typography variant="h6">
          <b>5.Contact Information:</b> If you have any questions, concerns, or
          requests regarding this privacy policy or the handling of your
          personal information, please contact us at the following address:
          [nakul610@gmail.com]
        </Typography>
      </Box>
    </Container>
  );
};

export default Privacy;
