import { MenuItem, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

const SelectWrapper = ({ name, options, ...rest }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const handleChange = (e) => {
    //console.log('seeee',e.target.value);
    setFieldValue(name, e.target.value);
  };
  const config = {
    ...field,
    ...rest,
    onChange: handleChange,
  };
  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <TextField {...config}>
      {options.map((el, index) => {
        return (
          <MenuItem key={`${el._id}${el.index}`} value={el._id}>
            {el.title}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SelectWrapper;
