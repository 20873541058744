import { Grid, Typography } from "@mui/material";
import Calendar from "./Calendar";
import { DownloadIcon } from "../../../assets/icons";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import moment from "moment";
import { useLazyDownloadMacroReportQuery } from "../../../apis/report/sessionRepApi";
import downloadExcel from "./downloadExcel";

const MacroReport = () => {
  // localState
  const [isDisable, setIsDisable] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  //   remoteState
  const [downloadMicro, { isLoading, isFetching }] =
    useLazyDownloadMacroReportQuery();

  const handleDownload = async () => {
    const sDate = startDate?._d && moment(startDate?._d).unix() * 1;
    const eDate = moment(endDate?._d).endOf("day").format("X") * 1;
    const finalData = [];
    try {
      const resp = await downloadMicro({ sDate, eDate }).unwrap();
      //console.log("resp-----", resp);
      for (let el of resp?.events) {
        const {
          Date,
          startTime,
          endTime,
          topic,
          isVirtuallyAllowed,
          attendees,
          locationId,
          leaderId,
          categoryId,
        } = el;

        if (attendees.length > 0) {
             for(let m of attendees){
                 finalData.push({
                   Date: Date,
                   startTime: startTime,
                   endTime: endTime,
                   location: locationId?.name,
                   parentCat: categoryId?.parentId?.title,
                   childCat: categoryId?.title,
                   topic: topic,
                   leader: `${leaderId?.fName} ${leaderId?.lName}`,
                   isVirtuallyAllowed: isVirtuallyAllowed ? "YES" : "NO",
                   attendence: m?.isVirtuallyJoined ? "Virtual" : "On Site",
                   inNy: m?.inNy ? "YES" : "NO",
                   joinTime: m?.joinTime,
                   udid: m?.memberId?.uid,
                   fName: m?.memberId?.fName,
                   lName: m?.memberId?.lName,
                   doa: m?.memberId?.doaCareNumber,
                   mType: m?.memberId?.mType,
                   phone: m?.memberId?.phone,
                   email: m?.memberId?.email,
                   gender: m?.memberId?.gender,
                   zip: m?.memberId?.zipCode,
                   insurance: m?.memberId?.insurance,
                   totalRec: resp?.totalRec,
                 });
             }
        } else {
          finalData.push({
            Date: Date,
            startTime: startTime,
            endTime: endTime,
            location: locationId?.name,
            parentCat: categoryId?.parentId?.title,
            childCat: categoryId?.title,
            topic: topic,
            leader: `${leaderId?.fName} ${leaderId?.lName}`,
            isVirtuallyAllowed: isVirtuallyAllowed ? "YES" : "NO",
            attendence: "",
            inNy: "",
            joinTime: "",
            udid: "",
            fName: "",
            lName: "",
            doa: "",
            mType: "",
            phone: "",
            email: "",
            gender: "",
            zip: "",
            insurance: "",
            totalRec: resp?.totalRec,
          });
        }
      }

      //console.log('final data---',finalData)
      downloadExcel(finalData,'IH Sesions Report-Macro')
    } catch (err) {
      console.log("API ERR--->", err);
    }
  };
  return (
    <>
      <Grid item md={12}>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Download Macro Sessions Reports
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Calendar
          label="Start Date"
          setFieldValue={setStartDate}
          setIsDisable={setIsDisable}
        />
      </Grid>
      <Grid item md={2}>
        <Calendar
          label="End Date"
          setFieldValue={setEndDate}
          setIsDisable={setIsDisable}
        />
      </Grid>
      <Grid item md={2} sx={{ alignSelf: "center" }}>
        <LoadingButton
          disabled={isDisable}
          startIcon={<DownloadIcon />}
          variant="contained"
          size="medium"
          loading={isLoading || isFetching}
          onClick={handleDownload}
        >
          CSV
        </LoadingButton>
      </Grid>
    </>
  );
};

export default MacroReport;
