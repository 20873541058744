import { Paper, Card, CardContent, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Default from "./../../assets/default.png";

const CommonCard = ({ profilePic, cardContent, to }) => {
  return (
    <Paper elevation={3}>
      <Card
        component={Link}
        to={to}
        sx={{
          backgroundColor: "none",
          boxShadow: "none",
          textDecoration: "none",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            // will help u in controlling paddding
            "&.MuiCardContent-root": {
              // Add your custom styles here
              padding: "12px",
            },
          }}
        >
          <Box sx={{ minWidth: "80px", maxWidth: "80px", height: "115px" }}>
            <img
              src={profilePic || Default}
              alt="Profile"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box>
            {cardContent.map((el, index) => {
              return (
                <Typography
                  variant="body2"
                  key={index}
                  sx={{
                    overflow: "hidden",
                    wordBreak:'break-all'
                  }}
                >
                  {el}
                </Typography>
              );
            })}
          </Box>
        </CardContent>
      </Card>
    </Paper>
  );
};
export default CommonCard;
