import { AppBar, Toolbar, Typography } from "@mui/material";

const Header = () => {
  return (
    <AppBar sx={{ boxShadow: "none" }}>
      <Toolbar sx={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
        <Typography variant="h4" sx={{ color: "#ffffff" }}>
          India Home CMS
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
