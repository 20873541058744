import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import CommonCard from "../../components/card";
import ModuleHeader from "../../components/commonHeader/ModuleHeader";
import { useGetAllMembersQuery } from "./../../apis/member/memberApi";
import CustomSkeleton from "./../../components/loaders/CustomSkeleton";
import NoRecords from "./../../components/norecords/NoRecords";
import CommonPagination from "./../../components/pagination/CommonPagination";
import Toast from "../../components/toast";
import { useLocation } from "react-router-dom";

const MemberContainer = () => {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [limit] = useState(16);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [isToastOpen, setIsToastOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [toastMsg, setToastMsg] = useState("");

  const { data, isFetching, isLoading } = useGetAllMembersQuery({
    page,
    limit,
    searchKeyword,
  });

  useEffect(() => {
    if (location?.state?.openToast) {
      setIsToastOpen(true);
      setToastMsg(location?.state?.toastMsg);
      setSeverity(location?.state?.severity);
    }
  }, [location?.state]);
  const uiLoader = () => {
    if (isFetching || isLoading) {
      return <CustomSkeleton />;
    } else if (data?.members.length == 0) {
      return <NoRecords />;
    } else if (data?.members.length > 0) {
      return data?.members?.map((el) => {
        const lastSeen =
          el?.lastSeen == 0
            ? "Not Logged In Yet"
            : moment.unix(el?.lastSeen).format("MMM,DD hh:mm A");
        const age = `${el?.age} Years Old`;
        const fullName = `${el?.fName.charAt(0).toUpperCase()}${el?.fName.slice(
          1
        )} ${el?.lName.charAt(0).toUpperCase()}${el?.lName.slice(1)}`;
        return (
          <Grid item lg={3} md={6} sm={12} xs={12} key={el._id}>
            <CommonCard
              profilePic={el?.profile || ""}
              cardContent={[fullName, el?.gender, age, el?.phone, lastSeen]}
              to={`/members/edit/${el._id}`}
            />
          </Grid>
        );
      });
    }
  };
  return (
    <>
      {isToastOpen && (
        <Toast
          isOpen={isToastOpen}
          setIsToastOpen={setIsToastOpen}
          severity={severity}
          message={toastMsg}
        />
      )}
      <Grid item md={12}>
        <ModuleHeader
          label="Search By Name,Email,Phone"
          buttonName="Add Member"
          moduleName="Members"
          isLogsIconVisible={true}
          setSearchKeyword={setSearchKeyword}
          logsTo="/members/logs/member"
          to="/members/add"
          isCalendarVisibl={false}
        />
      </Grid>
      {uiLoader()}
      {data?.members?.length > 0 && (
        <Grid item md={12} sx={{ mt: "7rem" }}>
          <CommonPagination
            page={page}
            totalPage={data?.totalPage}
            setPage={setPage}
          />
        </Grid>
      )}
    </>
  );
};
export default MemberContainer;
