import baseApi from "../baseApi";

const eventApi = baseApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getAllSession: builder.query({
        providesTags: ["Session"],
        query: ({ page, searchKeyword,startDate,endDate }) => {
          return {
            url: "/events",
            method: "GET",
            params: {
              page: page,
              limit: 12,
              searchKeyword: searchKeyword,
              startDate,
              endDate,
            },
          };
        },
      }),
      addSession: builder.mutation({
        invalidatesTags: ["Session"],
        query: (sessionData) => {
          return {
            url: "/events",
            method: "POST",
            body: sessionData,
          };
        },
      }),
      getSessionById: builder.query({
        providesTags: ["Session"],
        query: (sessionId) => {
          return {
            url: `/event/${sessionId}`,
            method: "GET",
          };
        },
      }),
      deleteSession: builder.mutation({
        invalidatesTags: ["Session"],
        query: (sessionId) => {
          return {
            url: `/event/${sessionId}`,
            method: "DELETE",
          };
        },
      }),
      editSession: builder.mutation({
        invalidatesTags: ["Session"],
        query: ({ sessionId, ...updatedData }) => {
          return {
            url: `/event/${sessionId}`,
            method: "PATCH",
            body: updatedData,
          };
        },
      }),
      removeAttendess: builder.mutation({
        invalidatesTags: ["Session"],
        query: ({ sessionId, ...attendeeData }) => {
          return {
            url: `/removeFromEvent/${sessionId}`,
            method: "PATCH",
            body: attendeeData,
          };
        },
      }),
      addAttendee: builder.mutation({
        invalidatesTags: ["Session"],
        query: ({ ...attendeeData }) => {
          return {
            url: "/eventJoin",
            method: "PATCH",
            body: attendeeData,
          };
        },
      }),
    };
  },
});

export const {
  useGetAllSessionQuery,
  useAddSessionMutation,
  useLazyGetSessionByIdQuery,
  useDeleteSessionMutation,
  useEditSessionMutation,
  useGetSessionByIdQuery,
  useRemoveAttendessMutation,
  useAddAttendeeMutation,
} = eventApi;
