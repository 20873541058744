import {
  Grid,
  MenuItem,
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import { DownloadIcon } from "../../../assets/icons";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import downloadExcel from "./downloadExcel";
import moment from "moment";
import { useLazyDownloadByBirthQuery } from "../../../apis/report/memberRepApi";

const birthMonthOptions = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Birthday = () => {
  // props if any

  // localState
  const [birthMonths, setBirthMonths] = useState([]);
  //   enable download button only when there is some value
  const [isDisable, setIsDisable] = useState(true);

  // remoteState
  const [downloadBirth, { isLoading, isFetching }] =
    useLazyDownloadByBirthQuery();

  // handlers
  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setBirthMonths(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setIsDisable(false);
  };

  const handleDownload = async () => {
    // convert array into string as api need in string form
    const birthMonth = birthMonths.join(",");
    try {
      const resp = await downloadBirth(birthMonth).unwrap();
      //console.log("memberData---", resp);
      const finalData = resp?.members.map((el) => {
        return {
          ...el,
          dob: moment.unix(el.dob).format("MM/DD/YYYY"),
          education: el?.education.join(","),
          language: el?.language.join(","),
        };
      });
      downloadExcel(finalData, "Members By Birthdays");
    } catch (err) {
      console.log("API ERR---->", err);
    }
  };

  // JSX
  return (
    <>
      <Grid item md={12}>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Download Members by Birthdays
        </Typography>
      </Grid>
      <Grid item md={4}>
        <FormControl fullWidth>
          <InputLabel id="demo-multiple-checkbox-label1">
            Select Month
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label1"
            id="demo-multiple-checkbox1"
            multiple
            value={birthMonths}
            onChange={handleChange}
            input={<OutlinedInput label="Select Month" />}
            renderValue={(selected) => selected.join(", ")}
            // MenuProps={MenuProps}
          >
            {birthMonthOptions.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={birthMonths.indexOf(option) > -1} />
                  <ListItemText primary={option} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={4} sx={{ alignSelf: "center" }}>
        <LoadingButton
          disabled={isDisable}
          startIcon={<DownloadIcon />}
          variant="contained"
          size="medium"
          loading={isLoading || isFetching}
          onClick={handleDownload}
        >
          CSV
        </LoadingButton>
      </Grid>
    </>
  );
};

export default Birthday;
