import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  AddCircleIcon,
  SettingsIcon,
  ListAltIcon,
  CalendarMonthIcon,
} from "../../assets/icons";
import { useEffect, useState } from "react";
import { getAdminFromLocalStorage } from "../../utils/localStorage";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Calendar from "../forms/Calendar";

const ModuleHeader = ({
  setSearchKeyword,
  buttonName,
  label,
  moduleName,
  to,
  settingTo,
  logsTo,
  isLogsIconVisible,
  isCalendarVisible,
  handleDateChange,
}) => {
  const theme = useTheme();
  const { admin } = useSelector((store) => {
    return store.auth;
  });
  const [term, setTerm] = useState("");
  const adminData = admin || getAdminFromLocalStorage();

  const [anchorEl, setAnchorEl] = useState(null);

  const openCalendarHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      setSearchKeyword(term);
    }, 500);
    return () => clearTimeout(interval);
  }, [term, setSearchKeyword]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {moduleName}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: ".5rem",
        }}
      >
        {isCalendarVisible && (
          <>
            <IconButton
              size="small"
              onClick={openCalendarHandler}
              sx={{ color: theme.palette.primary.main, width: 30, height: 30 }}
            >
              <CalendarMonthIcon />
            </IconButton>
            <Calendar
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              setPublishDate={handleDateChange}
            />
          </>
        )}
        {adminData?.roleId?.visibility || (
          <Box component={Link} to={settingTo}>
            <SettingsIcon
              sx={{ color: theme.palette.primary.main, width: 30, height: 30 }}
            />
          </Box>
        )}
        {isLogsIconVisible && (
          <Box component={Link} to={logsTo}>
            <Tooltip title="Logs">
              <ListAltIcon
                sx={{
                  color: theme.palette.primary.main,
                  width: 30,
                  height: 30,
                }}
              />
            </Tooltip>
          </Box>
        )}
        <TextField
          variant="outlined"
          label={label}
          size="small"
          value={term}
          onChange={(e) => {
            setTerm(e.target.value);
          }}
        />
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          component={Link}
          to={to}
        >
          {buttonName}
        </Button>
      </Box>
    </Box>
  );
};
export default ModuleHeader;
