import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CheckIcon, ClearIcon } from "../../assets/icons";
import { useState } from "react";

const ModalSingleField = ({
  label,
  isModalOpen,
  setIsModalOpen,
  isLoading,
  addDataInDB,
}) => {
  const [title, setTitle] = useState("");
  const [error, setError] = useState(false);
  const titleChangeHandler = (e) => {
    setTitle(e.target.value);
    setError(false);
  };
  const saveDataHandler = () => {
    if (title.length <= 2) {
      setError(true);
    } else {
      addDataInDB({ title });
      setIsModalOpen(false);
    }
  };
  return (
    <Dialog
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>Add</DialogTitle>
      <DialogContent>
        <TextField
          name={title}
          onChange={(e) => {
            titleChangeHandler(e);
          }}
          variant="standard"
          label={label}
          fullWidth
          error={error}
          helperText={error && "Field is required"}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          startIcon={<ClearIcon />}
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          startIcon={<CheckIcon />}
          loading={isLoading}
          onClick={saveDataHandler}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default ModalSingleField;
