import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const exportToExcel = (data, sheetName) => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.json_to_sheet(data);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, sheet, sheetName || "Sheet1");

  // Generate the XLSX file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Convert the buffer to a Blob and save the file
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${sheetName || "exported_data"}.xlsx`);
};

export default exportToExcel;
