import { useEffect, useState } from "react";
import { useGetAllLocationQuery } from "../../apis/location/locationApi";
import LocationCard from "./LocationCard";
import CustomSkeleton from "./../../components/loaders/CustomSkeleton";
import NoRecords from "./../../components/norecords/NoRecords";
import CommonPagination from "../../components/pagination/CommonPagination";
import { Grid } from "@mui/material";
import ModuleHeader from "../../components/commonHeader/ModuleHeader";
import Toast from "../../components/toast";
import { useLocation } from "react-router-dom";

const LocationContainer = () => {
  const location = useLocation();

  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [severity, setSeverity] = useState("");
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");

  const { data, isFetching, isSuccess } = useGetAllLocationQuery({
    page,
    limit,
    searchKeyword,
  });
  //console.log("api resp-->", data);
  useEffect(() => {
    setIsToastOpen(location?.state?.openToast);
    setToastMsg(location?.state?.toastMsg);
    setSeverity(location?.state?.severity);
  }, [location?.state]);

  const uiLoader = () => {
    if (isFetching) {
      return <CustomSkeleton />;
    } else if (data?.locations.length == 0) {
      return <NoRecords />;
    } else if (data?.locations.length > 0) {
      return data?.locations.map((el) => {
        return <LocationCard key={el._id} location={el} />;
      });
    }
  };
  return (
    <>
      {isToastOpen && (
        <Toast
          isOpen={isToastOpen}
          setIsToastOpen={setIsToastOpen}
          severity={severity}
          message={toastMsg}
        />
      )}
      <Grid item md={12}>
        <ModuleHeader
          setSearchKeyword={setSearchKeyword}
          buttonName="Add Location"
          label="Search By Name"
          moduleName="Location"
          settingTo="/locations/setting"
          to="/locations/add"
          isLogsIconVisible={false}
          isCalendarVisible={false}
        />
      </Grid>

      {uiLoader()}

      <Grid item md={12} sx={{ mt: "7rem" }}>
        {isSuccess && data?.locations.length > 0 && (
          <CommonPagination
            page={page}
            totalPage={data?.totalPage}
            setPage={setPage}
          />
        )}
      </Grid>
    </>
  );
};

export default LocationContainer;
