import { Box, Grid } from "@mui/material";
import SideBar from "../../components/sidebar";
import { Outlet } from "react-router-dom";
import { useState } from "react";

const SharedLayout = () => {
  const drawerWidth = 245;
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  return (
    <Box sx={{ display: "flex" }}>
      <SideBar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        drawerWidth={drawerWidth}
      />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <NavBar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        /> */}
        <Box
          sx={{
            flex: 1,
            marginTop: "1rem",
            marginLeft: "2.5rem",
            marginRight: "2.5rem",
            // border: "1px solid red",
            //backgroundColor: "#f4f4f4",

            // paddingLeft: "1rem",
            // paddingRight: "1rem",
          }}
        >
          <Grid container row rowSpacing={2} columnSpacing={2}>
            <Outlet />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SharedLayout;
