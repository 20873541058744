import baseApi from "../baseApi";

const sessionRepApi = baseApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      downloadMacroReport: builder.query({
        query: ({ sDate, eDate }) => {
          return {
            url: "/downloadSessions",
            method: "GET",
            params: {
              startDate: sDate,
              endDate: eDate,
            },
          };
        },
      }),
      downloadFilteredReport: builder.query({
        query: ({
          sDate,
          eDate,
          insurance,
          mType,
          gender,
          inNy,
          isVirtuallyJoined,
          location,
          childCat,
          parentCat,
        }) => {
          return {
            url: "/downloadSessions",
            method: "GET",
            params: {
              startDate: sDate,
              endDate: eDate,
              insurance,
              mType,
              gender,
              inNy,
              isVirtuallyJoined,
              location,
              childCat,
              parentCat,
            },
          };
        },
      }),
      getMtypeOptions: builder.query({
        query: () => {
          return {
            url: "/getAllMtype",
            method: "GET",
          };
        },
      }),
      getInsuranceOptions: builder.query({
        query: () => {
          return {
            url: "/getAllInsurance",
            method: "GET",
          };
        },
      }),
      getParentCatOptions: builder.query({
        query: () => {
          return {
            url: "/getAllParentCat",
            method: "GET",
          };
        },
      }),
      getChildCatOptions: builder.query({
        query: (parentIds) => {
          return {
            url: "/getAllChildCat",
            method: "GET",
            params: {
              parentIds,
            },
          };
        },
      }),
    };
  },
});

export const {
  useLazyDownloadMacroReportQuery,
  useGetInsuranceOptionsQuery,
  useGetParentCatOptionsQuery,
  useLazyGetChildCatOptionsQuery,
  useGetMtypeOptionsQuery,
  useLazyDownloadFilteredReportQuery,
} = sessionRepApi;
