import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  ImageListItemBar,
  ListItemIcon,
} from "@mui/material";
import * as yup from "yup";
import SettingHeader from "../../components/commonHeader/SettingHeader";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import UploadButton from "../../components/forms/UploadButton";
import SelectWrapper from "./../../components/forms/SelectWrapper";
import TextInputWrapper from "./../../components/forms/TextInputWrapper";
import { useState, useEffect } from "react";
import {
  useAddLocationMutation,
  useDeleteLocationMutation,
  useGetAllCategoryQuery,
  useLazyGetLocationByIdQuery,
  useUpdateLocationMutation,
} from "../../apis/location/locationApi";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/forms/SubmitButton";
import { useNavigate, useParams } from "react-router-dom";
import { ClearIcon } from "../../assets/icons";
import DeleteButton from "../../components/forms/DeleteButton";
import Spinner from "../../components/loaders/Spinner";

const initialState = {
  images: [],
  catId: "",
  name: "",
  city: "",
  desc: "",
  philosphy: "",
  capacity: "",
  gpsLocation: "",
  other: "",
};

const AddLocation = () => {
  const navigate = useNavigate();
  const { locationId } = useParams();

  const [isEditing, setIsEditing] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [locationData, setLocationData] = useState(initialState);

  // will use this for category options in dropdown
  const { data } = useGetAllCategoryQuery();
  const [addLocation] = useAddLocationMutation();
  const [getLocationById] = useLazyGetLocationByIdQuery();
  const [deleteLocation, { isLoading }] = useDeleteLocationMutation();
  const [updateLocation] = useUpdateLocationMutation();

  const validationSchema = yup.object({
    catId: yup.string().required("Category is required"),
    name: yup.string().required("Name is required"),
    city: yup.string().required("City is required"),
  });

  const onSubmit = async (values, onSubmitProps) => {
    if (isEditing) {
      try {
        const resp = await updateLocation({
          locationId,
          ...values,
        }).unwrap();
        if (resp.status) {
          navigate("/locations", {
            state: {
              openToast: true,
              toastMsg: resp?.msg,
              severity: "success",
            },
          });
        }
      } catch (err) {
        console.log("Edit Location API Err-->", err);
      }
    } else {
      try {
        const resp = await addLocation(values).unwrap();
        if (resp?.status) {
          navigate("/locations", {
            state: {
              openToast: true,
              toastMsg: resp?.msg,
              severity: "success",
            },
          });
        }
      } catch (err) {
        console.log("Location Add Api Err-->", err);
      }
    }
  };

  const locationImageArrHandler = (url) => {
    //console.log("from master-->", url);
    setLocationData((prevState) => {
      return { ...prevState, images: [...prevState.images, url] };
    });
  };

  const removeImageFromArrHandler = (index) => {
    const newImageArr = [...locationData.images];
    newImageArr.splice(index, 1);
    setLocationData((prevState) => {
      return { ...prevState, images: [...newImageArr] };
    });
  };

  const deleteLocationHandler = async () => {
    try {
      const resp = await deleteLocation(locationId).unwrap();
      if (resp.status) {
        navigate("/locations", {
          state: { openToast: true, toastMsg: resp?.msg, severity: "success" },
        });
      }
    } catch (err) {
      console.log("Location Delete API-->", err);
    }
  };

  useEffect(() => {
    if (locationId) {
      setIsEditing(true);
    }
  }, [locationId]);

  useEffect(() => {
    if (isEditing) {
      const getById = async () => {
        try {
          const resp = await getLocationById(locationId).unwrap();
          //console.log("Location GetById API resp-->", resp);
          if (resp?.status) {
            const { location } = resp;
            setLocationData((prevState) => {
              return {
                ...prevState,
                images: [...location.images],
                catId: location?.catId?._id,
                city: location?.city,
                desc: location?.desc,
                gpsLocation: location?.gpsLocation,
                name: location?.name,
                other: location?.other,
                capacity: location?.capacity,
                philosphy: location?.philosphy,
              };
            });
          }
        } catch (err) {
          console.log("Location GetById API ERR--->", err);
        }
      };
      getById();
    }
  }, [isEditing, getLocationById, locationId]);

  return (
    <>
      <Grid item md={12}>
        <SettingHeader
          moduleName={isEditing ? "Edit Location" : "Add Location"}
        />
      </Grid>

      <Grid item md={12}>
        <Formik
          enableReinitialize
          initialValues={locationData}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <Grid item md={12} container columnSpacing={1} rowSpacing={5}>
              <Grid item md={12}>
                {isImageUploading ? (
                  <CircularProgress size={30} />
                ) : (
                  <ImageList cols={6} gap={20}>
                    {locationData?.images.map((item, index) => (
                      <ImageListItem key={index}>
                        <Box
                          sx={{
                            position: "relative",
                            paddingTop: "56.25%", // 16:9 aspect ratio
                          }}
                        >
                          <img
                            src={`${item}`}
                            srcSet={`${item}`}
                            alt="location"
                            loading="lazy"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: 8,
                            }}
                          />
                        </Box>

                        <ImageListItemBar
                          title="Remove"
                          position="bottom"
                          actionIcon={
                            <ListItemIcon>
                              <IconButton
                                sx={{ color: "rgba(255, 255, 255, 0.9)" }}
                                onClick={() => {
                                  removeImageFromArrHandler(index);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </ListItemIcon>
                          }
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                )}
              </Grid>
              <Grid item md={12}>
                <UploadButton
                  setProfile={locationImageArrHandler}
                  aspectType={2}
                  setIsImageUploading={setIsImageUploading}
                />
              </Grid>
              <Grid item md={3}>
                <SelectWrapper
                  label="Location Category"
                  required
                  fullWidth
                  name="catId"
                  select
                  options={data?.categories || []}
                />
              </Grid>
              <Grid item md={7}>
                <TextInputWrapper label="Description" name="desc" fullWidth />
              </Grid>
              <Grid item md={3}>
                <TextInputWrapper
                  label="Location Name"
                  name="name"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item md={7}>
                <TextInputWrapper label="Address" name="philosphy" fullWidth />
              </Grid>
              <Grid item md={3}>
                <TextInputWrapper label="City" required name="city" fullWidth />
              </Grid>
              <Grid item md={7}>
                <TextInputWrapper
                  label="GPS Location"
                  fullWidth
                  name="gpsLocation"
                />
              </Grid>
              <Grid item md={3}>
                <TextInputWrapper label="Capacity" name="capacity" fullWidth />
              </Grid>
              <Grid item md={7}>
                <TextInputWrapper label="Other" name="other" fullWidth />
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <SubmitButton value={isEditing ? "Save & Exit" : "Add"} />
                  {isEditing && (
                    <DeleteButton
                      deleteHandler={deleteLocationHandler}
                      isLoading={isLoading}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Grid>
    </>
  );
};

export default AddLocation;
