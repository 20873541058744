import { Avatar, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useGetSessionByIdQuery } from "../../apis/event/eventApi";
import SettingHeader from "../../components/commonHeader/SettingHeader";
import Default from "./../../assets/default.png";
import RsvpCustomGridToolBar from "./RsvpCustomGridToolBar";
import moment from "moment";

const columns = [
  {
    field: "profile",
    headerName: "Pic",
    flex: 0.3,
    editable: false,
    renderCell: (params) => {
      return <Avatar src={params?.row?.profile || Default} alt="Profile" />;
    },
  },
  {
    field: "uid",
    headerName: "UID",
    flex: 0.5,
    editable: false,
    renderCell: (params) => {
      return params?.row?.uid;
    },
  },
  {
    field: "doaCareNumber",
    headerName: "DoACareNumber",
    flex: 0.7,
    editable: false,
    renderCell: (params) => {
      return params?.row?.doaCareNumber;
    },
  },
  {
    field: "fName",
    headerName: "FullName",
    flex: 1,
    editable: false,
    renderCell: (params) => {
      return `${params?.row?.fName
        .charAt(0)
        .toUpperCase()}${params?.row?.fName.slice(1)} ${params?.row?.lName}`;
    },
    valueGetter: (params) => {
      return `${params?.row?.fName
        .charAt(0)
        .toUpperCase()}${params?.row?.fName.slice(1)} ${params?.row?.lName}`;
    },
  },
  {
    field: "phone",
    headerName: "Phone Number",
    flex: 0.7,
    editable: false,
    renderCell: (params) => {
      return params?.row?.phone;
    },
  },
  {
    field: "email",
    headerName: "Email Id",
    flex: 1.2,
    editable: false,
    renderCell: (params) => {
      return params?.row?.email;
    },
  },
];

const RsvpList = () => {
  const { sessionId } = useParams();
  const { data } = useGetSessionByIdQuery(sessionId);
  //console.log("rsvp lists--->", data);

  let sheetName = `R-${moment
    .unix(data?.event?.startDateTime)
    .format("MM.DD.YYYY")}-${data?.event?.topic
    .replace(/[^\w\s]/gi, "")
    .substring(0, 15)}`;

  if (sheetName.length > 29) {
    sheetName = `R-${moment
      .unix(data?.event?.startDateTime)
      .format("MM.DD.YYYY")}-${data?.event?.topic
      .replace(/[^\w\s]/gi, "")
      .substring(0, 10)}`;
  }

  return (
    <>
      <Grid item md={12}>
        <SettingHeader moduleName="Event RSVP List" />
      </Grid>

      <Grid item md={7}>
        <DataGrid
          autoHeight={true}
          columns={columns}
          rows={(data && data.event.rsvp) || []}
          components={{
            Toolbar: RsvpCustomGridToolBar,
          }}
          componentsProps={{
            toolbar: {
              excelSheetFileName: `${sheetName}`,
              excelSheetData: data?.event?.rsvp || [],
              type: 2,
            },
          }}
        />
      </Grid>
    </>
  );
};

export default RsvpList;
