import baseApi from "../baseApi";

const dashboardApi = baseApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getDashboardData: builder.query({
        query: () => {
          return {
            url: "/dashboard",
            method: "GET",
          };
        },
      }),
    };
  },
});

export const { useGetDashboardDataQuery } = dashboardApi;
