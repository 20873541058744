import { Autocomplete, TextField, Box } from "@mui/material";
import { useField, useFormikContext } from "formik";

import Default from "./../../assets/default.png";

const AutoCompleteWrapper1 = ({ name, options, setProfile }) => {
  //console.log("from auto value", value);
  //console.log("from auto options", options);

  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const config1 = {};
  if (meta && meta.touched && meta.error) {
    config1.error = true;
    config1.helperText = meta.error;
    //console.log("confi1", config1);
  }
  const handleChange = (e, v) => {
    //console.log("onChnage", v);
    setFieldValue(name, v?._id);
    setProfile(v?.profile);
  };

  return (
    <Autocomplete
      disablePortal
      // isOptionEqualToValue={(option, value) => {
      //   return option._id == value._id;
      // }}
      onChange={handleChange}
      getOptionLabel={(option) => `${option?.fName} ${option?.lName}`}
      options={options}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="35"
            src={option?.profile || Default}
            alt={option?.fName}
          />
          {option.fName} {option.lName}
        </Box>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Activity Leader" {...config1} />
      )}
      noOptionsText={"No Records Found"}
    />
  );
};

export default AutoCompleteWrapper1;
