import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { usePublishMasterEventMutation } from "../../apis/master/masterApi";

import {
  AddCircleIcon,
  CalendarMonthIcon,
  ListAltIcon,
  PublishIcon,
} from "../../assets/icons";
import Calendar from "../../components/forms/Calendar";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import { getAdminFromLocalStorage } from "../../utils/localStorage";
import { useSelector } from "react-redux";

const MasterHeader = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const { admin } = useSelector((store) => {
    return store.auth;
  });
  const adminData = admin || getAdminFromLocalStorage();

  const [publishDate, setPublishDate] = useState("");
  const [publishMasterEvent, { isLoading }] = usePublishMasterEventMutation();

  const openCalendarHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlerPublish = async () => {
    //console.log("publish button clicked..", publishDate);
    //console.log("in timestamp", moment(publishDate._d).unix() * 1);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Check if the user's timezone is not New York
    if (userTimeZone !== "America/New_York") {
      alert(
        "You can only perform actions if your timezone is set to New York."
      );
      return;
    }
    const publishingDate = moment(publishDate._d).unix() * 1;
    try {
      const resp = await publishMasterEvent(publishingDate).unwrap();
      if (resp?.status) {
        navigate("/sessions");
      }
    } catch (err) {
      console.log("published api err-->", err);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          Master Calendar
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: ".5rem",
        }}
      >
        <Box component={Link} to="/masters/logs/master">
          <Tooltip title="Logs">
            <ListAltIcon
              sx={{
                color: theme.palette.primary.main,
                width: 27,
                height: 40,
              }}
            />
          </Tooltip>
        </Box>

        <IconButton size="large" onClick={openCalendarHandler}>
          <CalendarMonthIcon />
        </IconButton>
        <Calendar
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          setPublishDate={setPublishDate}
        />
        {adminData?.roleId?.visibility || (
          <LoadingButton
            variant="contained"
            startIcon={<PublishIcon />}
            onClick={handlerPublish}
            loading={isLoading}
          >
            Publish
          </LoadingButton>
        )}

        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          component={Link}
          to="/masters/add"
        >
          Add Master
        </Button>
      </Box>
    </Box>
  );
};

export default MasterHeader;
