import {
  Grid,
  MenuItem,
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import { DownloadIcon } from "../../../assets/icons";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import downloadExcel from "./downloadExcel";
import moment from "moment";
import {
  useGetZipCodeQuery,
  useLazyDownloadAreaCodeQuery,
} from "../../../apis/report/memberRepApi";

const AreaCode = () => {
  // props if any

  // localState
  const [zipCodeOptions, setZipCodeOptions] = useState([]);
  const [zipCodes, setZipCodes] = useState([]);
  const [isDisable, setIsDisable] = useState(true);

  // remoteState
  const { data, isSuccess } = useGetZipCodeQuery();
  const [downloadMember, { isLoading, isFetching }] =
    useLazyDownloadAreaCodeQuery();

  // handlers
  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setZipCodes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setIsDisable(false);
  };

  const handleDownload = async () => {
    // convert array into string as api need in string form
    const zip = zipCodes.join(",");
    try {
      const resp = await downloadMember(zip).unwrap();
      // console.log("memberData---", resp);
      const finalData = resp?.members.map((el) => {
        return {
          ...el,
          dob: moment.unix(el.dob).format("MM/DD/YYYY"),
          education: el?.education.join(","),
          language: el?.language.join(","),
        };
      });
      downloadExcel(finalData, "Members By AreaCode");
    } catch (err) {
      console.log("API ERR---->", err);
    }
  };

  // useEffect
  useEffect(() => {
    if (isSuccess) {
      setZipCodeOptions(data?.zipCode);
    }
  }, [data?.zipCode, isSuccess]);

  // JSX
  return (
    <>
      <Grid item md={12}>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Download Members by AreaCode
        </Typography>
      </Grid>
      <Grid item md={4}>
        <FormControl fullWidth>
          <InputLabel id="demo-multiple-checkbox-label">
            Select AreaCode
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={zipCodes}
            onChange={handleChange}
            input={<OutlinedInput label="Select AreaCode" />}
            renderValue={(selected) => selected.join(", ")}
            // MenuProps={MenuProps}
          >
            {isSuccess &&
              zipCodeOptions.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={zipCodes.indexOf(option) > -1} />
                    <ListItemText primary={option} />
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={4} sx={{ alignSelf: "center" }}>
        <LoadingButton
          disabled={isDisable}
          startIcon={<DownloadIcon />}
          variant="contained"
          size="medium"
          loading={isLoading || isFetching}
          onClick={handleDownload}
        >
          CSV
        </LoadingButton>
      </Grid>
    </>
  );
};

export default AreaCode;
