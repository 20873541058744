import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";

import SideLinks from "./SideLinks";
import { useLogoutMutation } from "../../apis/auth/authApi";
import { logoutAdmin } from "../../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAdminFromLocalStorage } from "../../utils/localStorage";
import { useNavigate } from "react-router";
import {
  LocationOnIcon,
  PeopleIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  SupervisorAccountIcon,
  EventRepeatIcon,
  LogoutIcon,
  PersonIcon,
  DownloadIcon,
  DashboardIcon,
} from "./../../assets/icons";
const modules = [
  {
    id: "1",
    name: "Dashboard",
    icon: <DashboardIcon />,
    to: "/",
  },
  {
    id: "2",
    name: "Member",
    icon: <PeopleIcon />,
    to: "/members",
  },
  {
    id: "3",
    name: "Staff",
    icon: <SupervisorAccountIcon />,
    to: "/staffs",
  },
  {
    id: "4",
    name: "Sessions",
    icon: <EventRepeatIcon />,
    to: "/sessions",
  },
  {
    id: "5",
    name: "Master Calendar",
    icon: <EventRepeatIcon />,
    to: "/masters",
  },
  {
    id: "6",
    name: "Locations",
    icon: <LocationOnIcon />,
    to: "/locations",
  },
  {
    id: "7",
    name: "Download Reports",
    icon: <DownloadIcon />,
    to: "/reports",
  },
];
const SideBar = ({ drawerWidth, isSidebarOpen, setIsSidebarOpen }) => {
  const { admin } = useSelector((store) => {
    return store.auth;
  });
  const adminData = admin || getAdminFromLocalStorage();
  //console.log('adminData-->',adminData);
  //console.log('admin from store-->',admin)
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  //console.log("admin value-->", admin);
  const [httpLogout] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      const resp = await httpLogout().unwrap();
      //console.log("logoutAPI resp-->", resp);
      if (resp.status) {
        dispatch(logoutAdmin());
        navigate("/login");
      }
    } catch (err) {
      //console.log("logoutAPI Err", err);
    }
  };
  return (
    <Box component="nav">
      <Drawer
        variant="permanent"
        sx={{
          minWidth: isSidebarOpen ? drawerWidth : "4rem",
          "& .MuiDrawer-paper": {
            minWidth: isSidebarOpen ? drawerWidth : "4rem",
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
          },
        }}
      >
        <Box
          sx={{
            flex: 1, //must
            padding: ".3rem",
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
          }}
        >
          {/* child1 logo */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Box component="img" src={logo} sx={{ width: 70 }} /> */}
            {isSidebarOpen && (
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                India Home CMS
              </Typography>
            )}
            <Box>
              <IconButton
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen);
                }}
              >
                {isSidebarOpen == true ? (
                  <ChevronLeftIcon fontSize="large" />
                ) : (
                  <ChevronRightIcon fontSize="large" />
                )}
              </IconButton>
            </Box>
          </Box>
          {/* child2 links */}
          <Box sx={{ flex: 1 }}>
            <List>
              {modules.map((el) => {
                return (
                  <SideLinks
                    key={el.id}
                    name={el.name}
                    icon={el.icon}
                    to={el.to}
                    isSidebarOpen={isSidebarOpen}
                  />
                );
              })}
            </List>
          </Box>
          <Divider variant="middle" />
          {/* child3 AdminName */}
          <Box>
            <List>
              <ListItem disablePadding disableGutters>
                <ListItemButton>
                  <Tooltip
                    title={adminData?.fName.toUpperCase()}
                    placement="right"
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                  </Tooltip>
                  {isSidebarOpen && (
                    <ListItemText
                      primary={adminData?.fName.toUpperCase()}
                      secondary={adminData?.roleId?.title}
                    />
                  )}
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding disableGutters>
                <ListItemButton onClick={logoutHandler}>
                  <Tooltip title="Logout" placement="right">
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                  </Tooltip>

                  {isSidebarOpen && <ListItemText primary="Logout" />}
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default SideBar;
