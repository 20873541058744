import { useField, useFormikContext } from "formik";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Typography } from "@mui/material";

const DateWrapper = ({ name, ...rest }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const config = {
    ...field,
    ...rest,
  };
  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }
  //console.log("config", config);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        sx={{
          width: "100%",
        }}
        {...config}
        format="MM/DD/YYYY"
        renderInput={(params) => {
          return <TextField {...params} />;
        }}
        slotProps={{
          textField: {
            helperText: meta?.error ? (
              <Typography
                variant="body2"
                component="span"
                sx={{ color: "red" }}
              >
                {meta?.error}
              </Typography>
            ) : (
              ""
            ),
          },
        }}
        onChange={(newValue) => setFieldValue(name, newValue)}
      />
    </LocalizationProvider>
  );
};

export default DateWrapper;
