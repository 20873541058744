import { Avatar, Box, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useGetSessionByIdQuery } from "../../apis/event/eventApi";
import SettingHeader from "../../components/commonHeader/SettingHeader";
import Default from "./../../assets/default.png";
import CustomDelete from "./CustomDelete";
import RsvpCustomGridToolBar from "./RsvpCustomGridToolBar";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import moment from "moment";

const AttendeesList = () => {
  // will be used for printing
  const tableRef = useRef(null);

  const columns = [
    {
      field: "memberId.profile",
      headerName: "Pic",
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        //console.log("params in profile", params.row);
        return (
          <Avatar
            src={params?.row?.memberId.profile || Default}
            alt="Profile"
          />
        );
      },
      valueGetter: (params) => params.row?.memberId?.profile || "",
    },
    {
      field: "memberId.fName",
      headerName: "FirstName",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        //return params.row?.memberId?.fName;
        return `${params.row?.memberId?.fName
          .charAt(0)
          .toUpperCase()}${params.row?.memberId?.fName.slice(1)}`;
      },
      valueGetter: (params) => {
        //return params.row?.memberId?.fName;
        return `${params.row?.memberId?.fName
          .charAt(0)
          .toUpperCase()}${params.row?.memberId?.fName.slice(1)}`;
      },
    },
    {
      field: "memberId.lName",
      headerName: "LastName",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return `${params.row?.memberId?.lName
          .charAt(0)
          .toUpperCase()}${params.row?.memberId?.lName.slice(1)}`;
      },
      valueGetter: (params) => {
        return `${params.row?.memberId?.lName
          .charAt(0)
          .toUpperCase()}${params.row?.memberId?.lName.slice(1)}`;
      },
    },
    {
      field: "isVirtuallyJoined",
      headerName: "Status",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return params.row?.isVirtuallyJoined ? "VIRTUAL" : "ON SITE";
      },
      valueGetter: (params) => {
        return params.row?.isVirtuallyJoined ? "VIRTUAL" : "ON SITE";
      },
    },

    {
      field: "signature",
      headerName: "Signature",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        //console.log("check", params.row);
        const signUrl = params.row?.signature || "";
        //console.log("sign", signUrl);
        return (
          <Box
            sx={{
              width: "50px",
              height: "50px",
            }}
          >
            {signUrl ? (
              <img
                src={signUrl}
                alt="member sign"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  overflow: "hidden",
                }}
              />
            ) : (
              "N/A"
            )}
          </Box>
        );
      },
      valueGetter: (params) => params.row?.signature || "", // Extract the value for the column
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        //console.log("delete params", params.row);
        return (
          <CustomDelete attendeeId={params.row?._id} sessionId={sessionId} />
        );
      },
    },
  ];
  const { sessionId } = useParams();
  const { data } = useGetSessionByIdQuery(sessionId);
  //console.log("datataaa---", data);

  let sheetName = `A-${moment
    .unix(data?.event?.startDateTime)
    .format("MM.DD.YYYY")}-${data?.event?.topic
    .replace(/[^\w\s]/gi, "")
    .substring(0, 15)}`;

  if (sheetName.length > 29) {
    sheetName = `A-${moment
      .unix(data?.event?.startDateTime)
      .format("MM.DD.YYYY")}-${data?.event?.topic
      .replace(/[^\w\s]/gi, "")
      .substring(0, 10)}`;
  }

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,

    documentTitle: `${sheetName}`,
  });

  return (
    <>
      <Grid item md={12}>
        <SettingHeader moduleName="Event Attendees" />
      </Grid>

      <Grid item md={7}>
        <DataGrid
          ref={tableRef}
          autoHeight={true}
          columns={columns}
          getRowId={(row) => {
            return row?.memberId?._id;
          }}
          rows={(data && data.event.attendees) || []}
          components={{
            Toolbar: RsvpCustomGridToolBar,
          }}
          componentsProps={{
            toolbar: {
              isAddButton: true,
              sessionId: sessionId,
              excelSheetFileName: `${sheetName}`,
              excelSheetData: data?.event?.attendees || [],
              type: 1,
              handlePrint: handlePrint,
            },
          }}
        />
      </Grid>
    </>
  );
};

export default AttendeesList;
