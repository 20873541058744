import { IconButton } from "@mui/material";
import { useState } from "react";
import { DeleteIcon } from "../../assets/icons";
import AreYouSure from "../modal/AreYouSure";

const DeleteIconButton = ({ deleteHandler, isLoading }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <>
      <AreYouSure
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        title={`Are you sure you would like to remove him/her from this session?`}
        isLoading={isLoading}
        deleteHandler={deleteHandler}
      />
      <IconButton
        variant="contained"
        size="large"
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
};

export default DeleteIconButton;
