import { Grid, Typography } from "@mui/material";
import MemberReportContainer from "./member/MemberReportContainer";
import SessionReportContainer from "./session/SessionReportContainer";
const ReportContainer = () => {
  return (
    <>
      <Grid item md={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          Download Reports
        </Typography>
      </Grid>

      {/* Members UI*/}
      <MemberReportContainer />
      <SessionReportContainer />
    </>
  );
};
export default ReportContainer;
