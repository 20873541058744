import { configureStore } from "@reduxjs/toolkit";
import baseApi from "./apis/baseApi";
import authSlice from "./features/authSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    [baseApi.reducerPath]:baseApi.reducer
  },
  middleware:(getDefaultMiddleware)=>{
   return getDefaultMiddleware().concat(baseApi.middleware)
  }
});

export default store;
