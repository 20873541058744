import baseApi from "../baseApi";
const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      generateOTP: builder.mutation({
        query: ({ phone, email, mode }) => {
          let postData = { mode: mode };
          if (phone) {
            postData.phone = phone;
          } else if (email) {
            postData.email = email;
          }
          return {
            url: "/generateOTP",
            method: "POST",
            body: postData,
          };
        },
      }),
      verifyOTP: builder.mutation({
        query: ({ otpId, otpCode }) => {
          return {
            url: "/verifyOTP",
            method: "POST",
            body: { otpId, otpCode },
          };
        },
      }),
      logout: builder.mutation({
        query: () => {
          return {
            url: "/logout",
            method: "POST",
          };
        },
      }),
    };
  },
});

export const {
  useGenerateOTPMutation,
  useVerifyOTPMutation,
  useLogoutMutation,
} = authApi;
export default authApi;
