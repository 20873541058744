import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

const CheckboxWrapper = ({ name, nameZoom, label1, label2, ...rest }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const handleChange = (e) => {
    setFieldValue(name, e.target.checked);
    // nameZoom has been used to set zoom link predefined value based on cond
    if (nameZoom && e.target.checked === true) {
      setFieldValue(
        nameZoom,
        "https://indiahome.zoom.us/j/7280154731?pwd=d1preTlFMng5NXBQdFlKVjVqenh5Zz09"
      );
    } else {
      if (nameZoom) {
        setFieldValue(nameZoom, "");
      }
    }
  };
  const config = {
    ...field,
    ...rest,
    onChange: handleChange,
  };
  let config1 = {};
  if (meta && meta.touched && meta.error) {
    config1.error = true;
    config1.helperText = meta.error;
  }
  return (
    <FormControl {...config1}>
      <FormLabel>{label1}</FormLabel>
      <FormGroup>
        <FormControlLabel
          label={label2}
          control={<Checkbox {...config} checked={config?.value} />}
        />
      </FormGroup>
      <FormHelperText>{config1.helperText}</FormHelperText>
    </FormControl>
  );
};
export default CheckboxWrapper;
