import { Grid} from "@mui/material";
import SettingHeader from "../../components/commonHeader/SettingHeader";
import NoRecords from "../../components/norecords/NoRecords";
import Spinner from "../../components/loaders/Spinner";
import ListDelete from "../../components/lists/ListDelete";
import FloatingButton from "../../components/forms/FloatingButton";
import { useState } from "react";
import ModalSingleField from "../../components/modal/ModalSingleField";

import {
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useGetAllCategoryQuery,
} from "../../apis/location/locationApi";

const Setting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, isFetching, isLoading } = useGetAllCategoryQuery();
  const [addCategory, { isLoading: isAddLoading }] = useAddCategoryMutation();
  const [deleteCategory] = useDeleteCategoryMutation();

  const uiLoader = () => {
    if (isFetching || isLoading) {
      return <Spinner />;
    } else if (data?.categories.length == 0) {
      return <NoRecords />;
    } else if (data?.categories.length > 0) {
      return data?.categories.map((el) => {
        return (
          <ListDelete key={el._id} items={el} deleteFromDB={deleteCategory} />
        );
      });
    }
  };
  return (
    <>
      <ModalSingleField
        label="Add Category"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isLoading={isAddLoading}
        addDataInDB={addCategory}
      />
      <Grid item md={12}>
        <SettingHeader moduleName="Location Categories" />
      </Grid>

      <Grid item md={3}>
        {uiLoader()}
      </Grid>
      <Grid item md={12}>
        <FloatingButton
          buttonName="Add Category"
          setIsModalOpen={setIsModalOpen}
        />
      </Grid>
    </>
  );
};
export default Setting;
