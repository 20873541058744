import {
  Avatar,
  Box,
  Card,
  CardContent,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import LoginForm from "./LoginForm";
import logo from "./../../assets/logo.jpeg";

const LoginCard = () => {
  //login with phone or emailId so that UI can be changed
  const [isLoginPhone, setIsLoginPhone] = useState(true);
  /*
  disable login with email or phone link 
  once otp has been generated
  */
  const [isAltOptionEnabled, setIsAltOptionEnabled] = useState(true);
  // mui theme access
  const theme = useTheme();

  return (
    <Paper elevation={20} sx={{ width: { md: "30%", sm: "70%" } }}>
      <Card>
        <CardContent>
          <Box sx={{ display: "flex", gap: "3rem", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                alt="logo"
                src={logo}
                sx={{ height: theme.spacing(12), width: theme.spacing(12) }}
              />
            </Box>
            {/* child1 */}
            <Box>
              <Typography variant="h5" sx={{ fontWeight: "700" }}>
                Single Authentication Sign In
              </Typography>
            </Box>
            {/* child2 */}
            <Box>
              {/*form  */}
              <LoginForm
                loginMode={isLoginPhone}
                setIsAltOptionEnabled={setIsAltOptionEnabled}
              />
            </Box>
            {/* child3 */}
            {isAltOptionEnabled && (
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    cursor: "pointer",
                    color: theme.palette.grey[600],
                  }}
                  onClick={() => {
                    setIsLoginPhone(!isLoginPhone);
                  }}
                >
                  {isLoginPhone === true
                    ? "SIGN IN WITH EMAIL"
                    : "SIGN IN WITH PHONE NUMBER"}
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Paper>
  );
};
export default LoginCard;
