import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGetAllMasterEventQuery } from "../../apis/master/masterApi";

import CommonCard from "../../components/card";
import CustomSkeleton from "../../components/loaders/CustomSkeleton";
import NoRecords from "../../components/norecords/NoRecords";
import CommonPagination from "../../components/pagination/CommonPagination";
import Toast from "../../components/toast";
import MasterHeader from "./MasterHeader";

const MasterContainer = () => {
  const location = useLocation();
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);

  const { data, isFetching, isLoading } = useGetAllMasterEventQuery({ page });

  //console.log("master event data", data?.events);

  useEffect(() => {
    setIsToastOpen(location?.state?.openToast);
    setSeverity(location?.state?.severity);
    setMessage(location?.state?.toastMsg);
  }, [location?.state]);
  const uiLoader = () => {
    if (isFetching || isLoading) {
      return <CustomSkeleton />;
    } else if (data?.events?.length === 0) {
      return <NoRecords />;
    } else if (data?.events?.length > 0) {
      return data?.events?.map((el) => {
        const activityLeaderName = `${el?.leaderId?.fName
          .charAt(0)
          .toUpperCase()}${el?.leaderId?.fName.slice(1)}`;
        return (
          <Grid item lg={3} md={6} sm={12} xs={12} key={el._id}>
            <CommonCard
              profilePic={el?.leaderId?.profile}
              to={`/masters/edit/${el._id}`}
              cardContent={[
                `Day${el?.day}`,
                `${el?.startTime} - ${el.endTime}`,
                el?.locationId?.name,
                activityLeaderName,
                el?.categoryId?.title,
                // `${el?.topic.slice(0, 20)}`,
              ]}
            />
          </Grid>
        );
      });
    }
  };

  return (
    <>
      {isToastOpen && (
        <Toast
          isOpen={isToastOpen}
          setIsToastOpen={setIsToastOpen}
          message={message}
          severity={severity}
        />
      )}
      <Grid item md={12}>
        <MasterHeader />
      </Grid>
      {uiLoader()}
      {data?.events?.length > 0 && (
        <Grid item md={12} sx={{ mt: "7rem" }}>
          <CommonPagination
            page={page}
            totalPage={data?.totalPage}
            setPage={setPage}
          />
        </Grid>
      )}
    </>
  );
};
export default MasterContainer;
