import { Button, Grid } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useState } from "react";
import { AddCircleIcon, DownloadIcon, PrintIcon } from "../../assets/icons";
import AddAttendee from "../../components/modal/AddAttendee";
import exportToExcel from "../../utils/exportToExcel";

const RsvpCustomGridToolBar = ({
  isAddButton,
  sessionId,
  excelSheetFileName,
  excelSheetData,
  type,
  handlePrint,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleExport = () => {
    let transformedData = [];
    // type 1 for attendees logic
    if (type == 1) {
      for (let el of excelSheetData) {
        transformedData.push({
          UID: el.memberId.uid,
          DoACareNumber: el.memberId.doaCareNumber,
          FirstName: el.memberId.fName,
          LastName: el.memberId.lName,
          Phone: el.memberId.phone,
          Email: el.memberId.email,
          Status: el.isVirtuallyJoined ? "VIRTUAL" : "ON SITE",
        });
      }
    }
    // type2 for rsvp logic
    else if (type == 2) {
      for (let el of excelSheetData) {
        transformedData.push({
          UID: el.uid,
          DoACareNumber: el.doaCareNumber,
          FullName: `${el?.fName.charAt(0).toUpperCase()}${el?.fName.slice(
            1
          )} ${el?.lName.charAt(0).toUpperCase()}${el?.lName.slice(1)}`,
          Phone: el.phone,
          Email: el.email,
        });
      }
    }

    exportToExcel(transformedData, excelSheetFileName);
  };

  return (
    <>
      <AddAttendee
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        sessionId={sessionId}
      />
      <Grid item md={12} sx={{ marginBottom: "10px" }}>
        <GridToolbarContainer sx={{ display: "flex", gap: "1rem" }}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <Button
            variant="contained"
            size="small"
            disableElevation
            startIcon={<DownloadIcon />}
            onClick={handleExport}
          >
            Export Data Xlsx
          </Button>

          {type == 1 && (
            <Button
              variant="contained"
              size="small"
              disableElevation
              startIcon={<PrintIcon />}
              onClick={handlePrint}
            >
              Print Data
            </Button>
          )}

          {isAddButton && (
            <Button
              variant="contained"
              size="small"
              disableElevation
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setIsDialogOpen(true);
              }}
            >
              Add Attendee
            </Button>
          )}
        </GridToolbarContainer>
      </Grid>
    </>
  );
};
export default RsvpCustomGridToolBar;
