import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetAllStaffQuery } from "../../apis/staff/staffApi";
import CommonCard from "../card";
import ModuleHeader from "../commonHeader/ModuleHeader";
import CustomSkeleton from "../loaders/CustomSkeleton";
import NoRecords from "../norecords/NoRecords";
import CommonPagination from "../pagination/CommonPagination";
import Toast from "../toast";

const Cards = () => {
  const location = useLocation();
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [toastMsg, setToastMsg] = useState("");

  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { data, isFetching, isLoading, isSuccess } = useGetAllStaffQuery({
    page,
    limit: 16,
    searchKeyword,
  });
  //console.log("GetAllStaffAPI-->", data);
  function loadContent() {
    if (isFetching || isLoading) {
      return <CustomSkeleton />;
    } else if (data?.staffs.length === 0) {
      return <NoRecords />;
    } else if (data?.staffs.length > 0) {
      return data?.staffs.map((el) => {
        //console.log("ell-->", el);
        const fullName = `${el?.fName.charAt(0).toUpperCase()}${el?.fName.slice(
          1
        )} ${el?.lName.charAt(0).toUpperCase()}${el?.lName.slice(1)}`;
        return (
          <Grid item lg={3} md={6} sm={12} xs={12} key={el._id}>
            <CommonCard
              key={el._id}
              profilePic={el?.profile || ""}
              cardContent={[
                fullName,
                el?.gender?.title || "",
                el?.roleId?.title || "",
                el?.phone || "",
                el?.email || "",
              ]}
              to={`/staffs/edit/${el._id}`}
            />
          </Grid>
        );
      });
    }
  }

  useEffect(() => {
    if (location?.state?.openToast) {
      setIsToastOpen(true);
      setToastMsg(location?.state?.toastMsg);
      setSeverity(location?.state?.severity);
    }
  }, [location?.state]);

  return (
    <>
      {isToastOpen && (
        <Toast
          isOpen={isToastOpen}
          setIsToastOpen={setIsToastOpen}
          severity={severity}
          message={toastMsg}
        />
      )}
      <Grid item md={12}>
        <ModuleHeader
          setSearchKeyword={setSearchKeyword}
          buttonName="Add Staff"
          label="Search By Name, Email, Phone"
          moduleName="Staff"
          to="/staffs/add"
          settingTo="/staffs/setting"
          logsTo="/staffs/logs/staff"
          isLogsIconVisible={true}
        />
      </Grid>
      {loadContent()}
      <Grid item md={12} sx={{ mt: "7rem" }}>
        {isSuccess && data?.staffs.length > 0 && (
          <CommonPagination
            page={page}
            totalPage={data?.totalPage}
            setPage={setPage}
          />
        )}
      </Grid>
    </>
  );
};

export default Cards;
