import { useTheme } from "@mui/material/styles";
import { Box, Pagination } from "@mui/material";
const CommonPagination = ({ page, totalPage, setPage }) => {
  const theme = useTheme();
  const pageChangeHandler = (e, value) => {
    setPage(value);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Pagination
        variant="outlined"
        shape="rounded"
        color="primary"
        size="large"
        count={totalPage}
        page={page}
        onChange={pageChangeHandler}
        sx={{
          "& .MuiPaginationItem-root": {
            // backgroundColor: theme.palette.primary.main,
            // color: theme.palette.common.white,
          },
          "& .Mui-selected": {
            color: theme.palette.common.white,
            backgroundColor: "red",
          },
        }}
      />
    </Box>
  );
};

export default CommonPagination;
