import { Grid, Skeleton, TextField, Button } from "@mui/material";

const FormLoader = () => {
  return (
    <Grid item md={12} container spacing={2}>
      <Grid item xs={5}>
        <Skeleton variant="text" animation="wave">
          <TextField label="Name" />
        </Skeleton>
      </Grid>
      <Grid item xs={5}>
        <Skeleton variant="text" animation="wave">
          <TextField label="Email" />
        </Skeleton>
      </Grid>
      <Grid item xs={5}>
        <Skeleton variant="text" animation="wave">
          <TextField label="City" />
        </Skeleton>
      </Grid>
      <Grid item xs={5}>
        <Skeleton variant="text" animation="wave">
          <TextField label="DOB" />
        </Skeleton>
      </Grid>
      <Grid item xs={5}>
        <Skeleton variant="text" animation="wave">
          <TextField label="phone" />
        </Skeleton>
      </Grid>
      <Grid item xs={5}>
        <Skeleton variant="text" animation="wave">
          <TextField label="age" />
        </Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" animation="wave">
          <TextField label="Message" multiline rows={4} />
        </Skeleton>
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="rect" width="100%" height={48} animation="wave">
          <Button variant="contained" color="primary">
            Send
          </Button>
        </Skeleton>
      </Grid>
    </Grid>
  );
};

export default FormLoader;
