import { Grid, Skeleton} from "@mui/material";

const CustomSkeleton = () => {
  const dataArr = [1, 2, 3, 4, 5, 6, 7, 8,9,10,11,12];
  function loadSkeleton() {
    return dataArr.map((el,index) => {
      return (
        <Grid item md={3} key={index}>
          <Skeleton animation="wave" variant="rectangular" height={150} />
        </Grid>
      );
    });
  }

  return <>{loadSkeleton()}</>;
};

export default CustomSkeleton;
