import { useTheme } from "@emotion/react";
import { IconButton, Typography, Box } from "@mui/material";
import { ArrowBackIcon } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
const SettingHeader = ({ moduleName }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        gap: theme.spacing(5),
        alignItems: "center",
      }}
    >
      <IconButton
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIcon sx={{ color: theme.palette.primary.main}} />
      </IconButton>
      <Typography variant="h5" sx={{ fontWeight: 700 }}>
        {moduleName}
      </Typography>
    </Box>
  );
};
export default SettingHeader;
