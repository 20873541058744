import baseApi from "./../baseApi";

const locationApi = baseApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getAllLocation: builder.query({
        providesTags: ["Location"],
        query: ({ page, limit, searchKeyword }) => {
          return {
            url: "/location",
            method: "GET",
            params: {
              page,
              limit,
              searchKeyword,
            },
          };
        },
      }),
      addLocation: builder.mutation({
        invalidatesTags: ["Location"],
        query: (locationData) => {
          return {
            url: "/location",
            method: "POST",
            body: locationData,
          };
        },
      }),
      getLocationById: builder.query({
        query: (locationId) => {
          return {
            url: `/location/${locationId}`,
            method: "GET",
          };
        },
      }),
      deleteLocation: builder.mutation({
        invalidatesTags: ["Location"],
        query: (locationId) => {
          return {
            url: `/location/${locationId}`,
            method: "DELETE",
          };
        },
      }),
      updateLocation: builder.mutation({
        invalidatesTags: ["Location"],
        query: ({ locationId, ...locationData }) => {
          return {
            url: `/location/${locationId}`,
            method: "PATCH",
            body: locationData,
          };
        },
      }),
      getAllCategory: builder.query({
        providesTags: ["LocationCat"],
        query: () => {
          return {
            url: "/catLocation",
            method: "GET",
          };
        },
      }),
      addCategory: builder.mutation({
        invalidatesTags: ["LocationCat"],
        query: (catData) => {
          return {
            url: "/catLocation",
            method: "POST",
            body: catData,
          };
        },
      }),
      deleteCategory: builder.mutation({
        invalidatesTags: ["LocationCat"],
        query: (catId) => {
          return {
            url: `/catLocation/${catId}`,
            method: "DELETE",
          };
        },
      }),
    };
  },
});

export const {
  useGetAllLocationQuery,
  useAddLocationMutation,
  useLazyGetLocationByIdQuery,
  useDeleteLocationMutation,
  useUpdateLocationMutation,
  useGetAllCategoryQuery,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
} = locationApi;
