import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Location from "./../../assets/location.jpeg";
import { Link } from "react-router-dom";

const LocationCard = ({ location }) => {
  const { _id, images, name, city } = location;
  return (
    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
      <Paper elevation={3}>
        <Card
          component={Link}
          to={`/locations/edit/${_id}`}
          sx={{ boxShadow: "none", textDecoration: "none" }}
        >
          <CardMedia
            component="img"
            src={images.length > 0 ? images[0] : Location}
            height="150"
          />
          <CardContent>
            <Typography variant="body1">
              {name}, {city}
            </Typography>
          </CardContent>
        </Card>
      </Paper>
    </Grid>
  );
};

export default LocationCard;
