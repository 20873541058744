import AreaCode from "./AreaCode";
import Birthday from "./Birthday";
import Mtype from "./Mtype";
const MemberReportContainer = () => {
  return (
    <>
      <Mtype />
      <AreaCode />
      <Birthday />
    </>
  );
};

export default MemberReportContainer;
