import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import { useState } from "react";
const CheckboxDropDown = ({ name, options, label }) => {
  const [field, meta] = useField(name);
  //console.log('field value',field);
  const { setFieldValue } = useFormikContext();

  const [selectedOptions, setSelectedOptions] = useState(field.value);

  const handleChange = (e) => {
    setSelectedOptions(e.target.value);
    setFieldValue(name, e.target.value);
  };
  const config = {
    ...field,
    multiple: true,
    input: <OutlinedInput label={label} />,
    onChange: handleChange,
    renderValue: (selected) => selected.join(", "),
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
      <Select {...config}>
        {options.map((option, index) => {
          return (
            <MenuItem key={`${option._id}${option.index}`} value={option._id}>
              <Checkbox checked={selectedOptions.indexOf(option.title) > -1}/>
              {option.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CheckboxDropDown;
