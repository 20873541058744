import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const downloadExcel = (data, sheetName) => {
  const customHeaders = {
    Date: "Date",
    startTime: "StartTime",
    endTime: "EndTime",
    location: "Location",
    parentCat: "Parent Category",
    childCat: "Child Category",
    topic: "Topic",
    leader: "Activity Leader",
    isVirtuallyAllowed: "Virtual Allowed",
    attendence: "Attendence",
    inNy: "In NY",
    joinTime: "Join Time",
    udid: "Member UDID",
    fName: "First Name",
    lName: "Last Name",
    doa: "DoA No",
    mType: "Membership Type",
    phone: "Phone Number",
    email: "Email Id",
    gender: "Gender",
    zip: "Zip Code",
    insurance: "Health Insurance",
    totalRec:"Total Number"
  };

  // Map the data to align with custom headers
  const formattedData = data.map((item) => {
    const formattedItem = {};
    for (const key in item) {
      if (customHeaders[key]) {
        formattedItem[customHeaders[key]] = item[key];
      }
    }
    return formattedItem;
  });
  // Create a new workbook
  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.json_to_sheet(formattedData);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, sheet, sheetName || "Sheet1");

  // Generate the XLSX file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Convert the buffer to a Blob and save the file
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${sheetName || "exported_data"}.xlsx`);
};

export default downloadExcel;
