const { Container, Box, Typography } = require("@mui/material");

const Term = () => {
  return (
    <Container maxWidth="sm" sx={{ paddingTop: "2rem" }}>
      <Typography variant="h4" gutterBottom>
        Terms and Conditions
      </Typography>
      <Typography variant="h6" gutterBottom>
        Welcome to our India Home By accessing and using our services, you agree
        to comply with the following terms and conditions.Please read them
        carefully:
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Typography variant="h6">
          <b>1.Purpose:</b> Our senior citizen project aims to provide various
          services, resources, and activities tailored for the well-being and
          support of senior citizens. These may include health
          checkups,recreational programs, educational sessions, and social
          events.
        </Typography>
        <Typography variant="h6">
          <b>2.Registration and Information:</b>To access our project's
          services,you may be required to provide certain personal information
          during the registration process. You agree to provide accurate,
          complete, and up-to-date information. We respect your privacy and
          handle your personal data in accordance with our privacy policy.
        </Typography>
        <Typography variant="h6">
          <b>3.External Links:</b> Our project may contain links to external
          websites or resources. These links are provided for convenience and
          donot imply endorsement or responsibility for the content or practices
          of these third-party sites. We recommend exercising caution and
          reviewing the terms and conditions and privacy policies of any
          external sites you visit.
        </Typography>
        
        <Typography variant="h6">
          <b>4.Contact Information:</b> If you have any questions or concerns
          regarding these terms and conditions, please contact us at
          [nakul610@gmail.com]. By using our services, you acknowledge that you have read, understood, and agreed to these terms and conditions. Thank you for being a part of India Home.
        </Typography>
      </Box>
    </Container>
  );
};

export default Term;
