import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./../pages/login";
import ProtectedRoute from "./../pages/ProtectedRoute";
import SharedLayout from "./../pages/sharedLayout";
import Staff from "./../pages/staff";
import AddStaff from "./../pages/staff/AddStaff";
import StaffSetting from "../pages/staff/Setting";
import LocationSetting from "./../pages/location/Setting";
import Dashboard from "../pages/dashboard";
import Location from "../pages/location";
import AddLocation from "../pages/location/AddLocation";
import Members from "./../pages/members";
import CommonLog from "../components/logTable";
import AddMember from "../pages/members/AddMember";
import PrintCard from "../pages/members/PrintCard";
import Master from "../pages/master";
import AddMaster from "../pages/master/AddMaster";
import Event from "../pages/event";
import AddEvent from "../pages/event/AddEvent";
import RsvpList from "../pages/event/RsvpList";
import AttendeesList from "../pages/event/AttendeesList";
import Report from "../pages/report";
import NotFound from "../pages/notfound";
import Privacy from "../pages/privacy";
import Term from "../pages/terms";
const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="members" element={<Members />} />
          <Route path="members/logs/:logsFor" element={<CommonLog />} />
          <Route path="members/add" element={<AddMember />} />
          <Route path="members/edit/:memberId" element={<AddMember />} />
          <Route path="members/print/card/:memberId" element={<PrintCard />} />
          <Route path="staffs" element={<Staff />} />
          <Route path="staffs/logs/:logsFor" element={<CommonLog />} />
          <Route path="staffs/add" element={<AddStaff />} />
          <Route path="staffs/edit/:staffId" element={<AddStaff />} />
          <Route path="staffs/setting" element={<StaffSetting />} />
          <Route path="locations" element={<Location />} />
          <Route path="locations/setting" element={<LocationSetting />} />
          <Route path="locations/add" element={<AddLocation />} />
          <Route path="locations/edit/:locationId" element={<AddLocation />} />
          <Route path="masters" element={<Master />} />
          <Route path="masters/add" element={<AddMaster />} />
          <Route path="masters/edit/:masterId" element={<AddMaster />} />
          <Route path="masters/logs/:logsFor" element={<CommonLog />} />
          <Route path="sessions" element={<Event />} />
          <Route path="sessions/add" element={<AddEvent />} />
          <Route path="/sessions/edit/:sessionId" element={<AddEvent />} />
          <Route path="sessions/logs/:logsFor" element={<CommonLog />} />
          <Route path="sessions/rsvp/:sessionId" element={<RsvpList />} />
          <Route path="reports" element={<Report />} />
          <Route
            path="sessions/attendees/:sessionId"
            element={<AttendeesList />}
          />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="terms" element={<Term />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Routing;
