import baseApi from "./../baseApi";

const memberRepApi = baseApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getMtype: builder.query({
        query: () => {
          return {
            url: "/getAllMtype",
            method: "GET",
          };
        },
      }),
      downloadMtype: builder.query({
        query: (mType) => {
          return {
            url: `/downloadMtype/${mType}`,
            method: "GET",
          };
        },
      }),
      getZipCode: builder.query({
        query: () => {
          return {
            url: "/getAllZip",
            method: "GET",
          };
        },
      }),
      downloadAreaCode: builder.query({
        query: (zipCodes) => {
          return {
            url: `/downloadZipCode`,
            method: "GET",
            params: {
              zipCodes,
            },
          };
        },
      }),
      downloadByBirth: builder.query({
        query: (birthMonths) => {
          return {
            url: `/downloadDOB`,
            method: "GET",
            params: {
              birthMonths: birthMonths,
            },
          };
        },
      }),
    };
  },
});

export const {
  useGetMtypeQuery,
  useLazyDownloadMtypeQuery,
  useGetZipCodeQuery,
  useLazyDownloadAreaCodeQuery,
  useLazyDownloadByBirthQuery,
} = memberRepApi;
