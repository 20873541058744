import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import { NavLink, useLocation } from "react-router-dom";

const SideLinks = ({ name, icon, to, isSidebarOpen }) => {
  const location = useLocation();

  return (
    <ListItem disableGutters disablePadding>
      <ListItemButton
        component={NavLink}
        to={to}
        selected={location.pathname.substring(1, 3) == to.substring(1, 3)}
      >
        <Tooltip title={name} placement="right">
          <ListItemIcon>{icon}</ListItemIcon>
        </Tooltip>

        {isSidebarOpen && (
          <ListItemText
            primary={name}
            sx={{ fontWeight: 700, fontSize: "3rem" }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};
export default SideLinks;
