import React, { useEffect } from "react";
import Highcharts from "highcharts";

function SimpleLineGraph() {
  useEffect(() => {
    // Initialize Highcharts
    Highcharts.chart("container", {
      title: {
        text: "Simple Line Graph",
      },
      accessibility: {
        enabled: false,
      },
      series: [
        {
          data: [100, 72, 135, 190, 57, 98],
          name: "Data",
        },
      ],
    });
  }, []);

  return <div id="container"></div>;
}

export default SimpleLineGraph;
