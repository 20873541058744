import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAddAttendeeMutation } from "../../apis/event/eventApi";
import { useLazyGetAllMembersQuery } from "../../apis/member/memberApi";
import { ClearIcon } from "../../assets/icons";
import Toast from "../toast";
import Default from "./../../assets/default.png";

const AddAttendee = ({ isDialogOpen, setIsDialogOpen, sessionId }) => {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [memberId, setMemberId] = useState("");
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [getAllMember, { isLoading }] = useLazyGetAllMembersQuery();
  const [addAttendee, { isLoading: isAdding }] = useAddAttendeeMutation();

  useEffect(() => {
    let debounceTimer;

    const searchMembers = async (value) => {
      if (searchText) {
        try {
          const response = await getAllMember({
            page: 1,
            limit: 20,
            searchKeyword: searchText,
          }).unwrap();
          //console.log("api resp---", response);
          const data = response?.members;
          setOptions(data);
        } catch (error) {
          console.error("Error searching members:", error);
        }
      } else {
        setOptions([]);
      }
    };

    const handleDebouncedSearch = (value) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        searchMembers(value);
      }, 1500); // Adjust the debounce delay as needed
    };

    handleDebouncedSearch(searchText);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchText, getAllMember]);

  const handleSearchTextChange = (event, value) => {
    setSearchText(value);
  };

  const handleChange = (e, v) => {
    //console.log("finally selected", v);
    setMemberId(v?._id);
    setError(false);
    setHelperText("");
  };

  const addAttendeeHandler = async () => {
    //console.log("reday to fire", sessionId, memberId);
    if (!memberId) {
      setError(true);
      setHelperText("Attendee is required");
    } else {
      const addData = {
        memberId: memberId,
        eventIds: [sessionId],
        signature: "",
      };
      try {
        const resp = await addAttendee(addData).unwrap();
        //console.log("add attendee api resp--->", resp);
        if (resp?.status) {
          setIsDialogOpen(false);
          setIsToastOpen(true);
          setSeverity("success");
          setMessage("Member Added");
        }
      } catch (err) {
        //console.log("add attendee api err-->", err);
      }
    }
  };

  return (
    <>
      {isToastOpen && (
        <Toast
          isOpen={isToastOpen}
          setIsToastOpen={setIsToastOpen}
          message={message}
          severity={severity}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">ADD MEMBER</Typography>
            <IconButton
              variant="contained"
              onClick={() => {
                setIsDialogOpen(false);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            sx={{
              "& .MuiInputLabel-root": {
                transform: "translate(0, -22%)",
                backgroundColor: "white",
                padding: "10 14px",
              },
            }}
            options={options}
            onChange={handleChange}
            getOptionLabel={(option) => `${option?.fName} ${option?.lName}`} // Replace 'name' with the actual property name to display in the autocomplete options
            onInputChange={handleSearchTextChange}
            loading={isLoading}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="35"
                  src={option?.profile || Default}
                  alt={option?.fName}
                />
                {option.fName} {option.lName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error}
                helperText={helperText}
                placeholder="Search by Name"
                variant="outlined"
                value={searchText}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            onClick={addAttendeeHandler}
            loading={isAdding}
          >
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddAttendee;
