import baseApi from "../baseApi";

const staffApi = baseApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getAllStaff: builder.query({
        providesTags: ["Staff"],
        query: ({ page, limit, searchKeyword, isActivityLeader }) => {
          return {
            url: "/staffs",
            method: "GET",
            params: {
              page,
              limit,
              searchKeyword,
              isActivityLeader,
            },
          };
        },
      }),
      getStaffById: builder.query({
        query: (staffId) => {
          return {
            url: `/staff/${staffId}`,
            method: "GET",
          };
        },
      }),
      getStaffLogs: builder.query({
        providesTags: ["Staff"],
        query: ({ page, limit, logFor }) => {
          return {
            url: "/commonlogs",
            method: "GET",
            params: {
              page,
              limit,
              logFor,
            },
          };
        },
      }),
      getAllRoles: builder.query({
        providesTags: ["StaffRole"],
        query: (hidden) => {
          return {
            url: "/roles",
            method: "GET",
            params: {
              hidden,
            },
          };
        },
      }),
      getGender: builder.query({
        query: () => {
          return {
            url: "/membersetting",
            method: "GET",
          };
        },
        transformResponse: (response) => {
          //console.log("transferRespone", response);
          const gender = response.settings.find((el) => {
            return el._id == 1;
          });
          //console.log("gender", gender);
          const { items } = gender;
          //console.log("items", items);
          return items;
        },
      }),
      addRole: builder.mutation({
        query: (roleData) => {
          return {
            url: "/role",
            method: "POST",
            body: roleData,
          };
        },
        invalidatesTags: ["StaffRole"],
      }),
      deleteRole: builder.mutation({
        invalidatesTags: ["StaffRole"],
        query: (roleId) => {
          //console.log("baseapi", roleId);
          return {
            url: `/role/${roleId}`,
            method: "DELETE",
          };
        },
      }),
      addStaff: builder.mutation({
        invalidatesTags: ["Staff"],
        query: (staffData) => {
          return {
            url: "/staff",
            method: "POST",
            body: staffData,
          };
        },
      }),
      editStaff: builder.mutation({
        invalidatesTags: ["Staff"],
        query: ({ staffId, ...updateData }) => {
          return {
            url: `/staff/${staffId}`,
            method: "PATCH",
            body: updateData,
          };
        },
      }),
      deleteStaff: builder.mutation({
        invalidatesTags: ["Staff"],
        query: (staffId) => {
          return {
            url: `/staff/${staffId}`,
            method: "DELETE",
          };
        },
      }),
    };
  },
});

export const {
  useGetAllStaffQuery,
  useGetStaffByIdQuery,
  useLazyGetStaffByIdQuery,
  useGetStaffLogsQuery,
  useGetAllRolesQuery,
  useGetGenderQuery,
  useAddRoleMutation,
  useDeleteRoleMutation,
  useAddStaffMutation,
  useDeleteStaffMutation,
  useEditStaffMutation,
} = staffApi;
export default staffApi;
