import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
const TimerContainer = ({
  setIsTimerExpired,
  setIsAltOptionEnabled,
  setOtpCodeErr,
  setOtpCodeErrMsg,
  setOtpId,
}) => {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const [counter, setCounter] = useState(120);

  useEffect(() => {
    let interval;
    if (counter === 0) {
      setIsTimerExpired(false);
      setIsAltOptionEnabled(true);
      setOtpId("");
      setOtpCodeErr(false);
      setOtpCodeErrMsg("");
    }
    if (counter > 0) {
      interval = setInterval(() => {
        setCounter(counter - 1);
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    counter,
    minutes,
    seconds,
    setIsTimerExpired,
    setIsAltOptionEnabled,
    setOtpCodeErr,
    setOtpCodeErrMsg,
    setOtpId,
  ]);

  return (
    <Typography variant="body2">
      OTP Will expire in {minutes}:{seconds}
    </Typography>
  );
};

export default TimerContainer;
