import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  useTheme,
  Paper,
} from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import EventIcon from "@mui/icons-material/Event";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SimpleLineGraph from "../../components/charts/line";
import { useGetDashboardDataQuery } from "../../apis/dashboard/dashboardApi";

const Dashboard = () => {
  const theme = useTheme();
  const { data } = useGetDashboardDataQuery();

  const cards = [
    {
      id: 1,
      data: data?.totalMember,
      label: "Total Members",
      icon: <BarChartIcon />,
      backgroundColor: "#ffffff",
    },
    {
      id: 2,
      data: data?.totalStaff,
      label: "Total Staff",
      icon: <PeopleAltIcon />,
      backgroundColor: "#ffffff",
    },
    {
      id: 3,
      data: 4,
      label: "Total Center",
      icon: <AddLocationIcon />,
      backgroundColor: "#ffffff",
    },

    {
      id: 4,
      data: data?.totalEvent,
      label: "Total Event",
      icon: <EventIcon />,
      backgroundColor: "#ffffff",
    },
  ];
  return (
    <>
      <Grid item md={12} sm={12}>
        <Typography variant="h4">Dashboard</Typography>
      </Grid>
      <Grid item md={12} container columnSpacing={2}>
        {cards.map((el) => {
          const backgroundColor = el.backgroundColor;
          return (
            <Grid item md={3} sm={4} key={el.id}>
              <Paper elevation={2}>
                <Card
                  sx={{ boxShadow: "none", backgroundColor: backgroundColor }}
                >
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box>
                        <Typography variant="h3">{el.data}</Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: theme.palette.common.black }}
                        >
                          {el.label}
                        </Typography>
                      </Box>
                      <Box sx={{ alignSelf: "center" }}>{el.icon}</Box>
                    </Box>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          );
        })}
      </Grid>

      <Grid item md={12} sm={12}>
        <Paper elevation={2}>
          <Card sx={{ boxShadow: "none" }}>
            <CardContent>
              <SimpleLineGraph />
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </>
  );
};

export default Dashboard;
