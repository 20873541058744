import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { DownloadIcon } from "../../../assets/icons";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useGetMtypeQuery,
  useLazyDownloadMtypeQuery,
} from "../../../apis/report/memberRepApi";
import { useEffect, useState } from "react";
import downloadExcel from "./downloadExcel";
import moment from "moment";

const Mtype = () => {
  // props if any

  // localState
  const [mTypeOptions, setMTypeOptions] = useState([]);
  const [mType, setMType] = useState("All");
  // remoteState
  const { data, isSuccess } = useGetMtypeQuery();
  const [downloadData, { isLoading, isFetching }] = useLazyDownloadMtypeQuery();
  // globalState

  // handlers
  const handleChange = (e) => {
    setMType((prevState) => {
      prevState = e.target.value;
      return prevState;
    });
  };

  const handleDownload = async () => {
    try {
      const resp = await downloadData(mType).unwrap();
      //console.log("memberData---", resp);
      const finalData = resp?.members.map((el) => {
        return {
          ...el,
          dob: moment.unix(el.dob).format("MM/DD/YYYY"),
          education: el?.education.join(","),
          language: el?.language.join(","),
        };
      });
      downloadExcel(finalData, "Full Members List");
    } catch (err) {
      console.log("API ERR---->", err);
    }
  };

  // useEffect
  useEffect(() => {
    if (isSuccess) {
      setMTypeOptions(data?.mType);
    }
  }, [data?.mType, isSuccess]);

  // JSX
  return (
    <>
      <Grid item md={12} sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Download Full Members List
        </Typography>
      </Grid>
      <Grid item md={4}>
        <TextField
          variant="outlined"
          label="Select Membership Type"
          select
          fullWidth
          value={mType}
          onChange={handleChange}
        >
          <MenuItem value="All">All</MenuItem>
          {isSuccess &&
            mTypeOptions.map((el) => {
              return (
                <MenuItem key={el._id} value={el.title}>
                  {el.title}
                </MenuItem>
              );
            })}
        </TextField>
      </Grid>
      <Grid item md={4} sx={{ alignSelf: "center" }}>
        <LoadingButton
          startIcon={<DownloadIcon />}
          variant="contained"
          size="medium"
          loading={isLoading || isFetching}
          onClick={handleDownload}
        >
          CSV
        </LoadingButton>
      </Grid>
    </>
  );
};

export default Mtype;
