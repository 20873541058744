import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#91cb35",
      light: "#eeeeee",
    },
    common: {
      black: "#000000",
      white: "#ffffff",
    },
    background: {
      default: "#f4f4f4",
      alt: "#eeeeee",
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
    },
  },
  typography: {
    fontFamily: "Roboto",
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
        },
        columnHeaders: {
          backgroundColor: "#bdbdbd",
          color: "#000",
          fontSize: "1rem",
        },
        footerContainer: {
          borderTop: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#fff",
          backgroundColor: "#91cb35",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          //backgroundColor: "#9e9e9e", // set your background color here
          color: "#757575", // set your text color here
          "&.Mui-selected": {
            color: "#ffffff",
            backgroundColor: "#91cb35",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#e0e0e0",
            color: "#000",
            fontWeight: "bold",
          },
        },
      },
    },
    MuiTimePickerToolbar: {
      styleOverrides: {
        ampmLabel: {
          // color: "#000",
          fontSize: "18px",
          backgroundColor: "#fff",
        },
        amPmSelection: {
          fontSize: "22px",
          backgroundColor: "#91cb35",
        },
      },
    },
    // MuiDataGrid: {
    //   styleOverrides: {
    //     toolbarContainer: {
    //       "&:hover": {
    //         backgroundColor: "grey",
    //       },
    //     },
    //   },
    // },
  },
});

export default theme;
