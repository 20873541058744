import { Grid, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { boolean, number, object, string } from "yup";
import SettingHeader from "../../components/commonHeader/SettingHeader";
import TextInputWrapper from "./../../components/forms/TextInputWrapper";
import SelectWrapper from "./../../components/forms/SelectWrapper";
import TextareaWrapper from "./../../components/forms/TextareaWrapper";
import TimeWrapper from "../../components/forms/TimeWrapper";
import CheckboxWrapper from "./../../components/forms/CheckboxWrapper";
import Default from "./../../assets/default.png";
import SubmitButton from "./../../components/forms/SubmitButton";
import {
  useGetAllActivityLeaderQuery,
  useGetAllEventCatQuery,
  useGetAllEventLocationsQuery,
  useAddMasterEventMutation,
  useLazyGetMasterEventByIdQuery,
  useDeleteMasterEventMutation,
  useEditMasterEventMutation,
} from "../../apis/master/masterApi";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import DeleteButton from "../../components/forms/DeleteButton";
import AutoCompleteWrapper1 from "../../components/forms/AutoCompleteWrapper1";

const initialState = {
  day: "",
  startDateTime: null,
  endDateTime: null,
  leaderId: null,
  categoryId: "",
  locationId: "",
  topic: "",
  about: "",
  zoom: "",
  isVirtuallyAllowed: true,
};

const AddMaster = () => {
  const navigate = useNavigate();
  const { masterId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [profile, setProfile] = useState("");
  const [masterData, setMasterData] = useState(initialState);

  const { data: locationOptions } = useGetAllEventLocationsQuery();
  const { data: categoryOptions } = useGetAllEventCatQuery();
  const { data: activityLeaderOptions, isSuccess } =
    useGetAllActivityLeaderQuery();
  const [addMaster] = useAddMasterEventMutation();
  const [getMasterById] = useLazyGetMasterEventByIdQuery();
  const [deleteMaster, { isLoading }] = useDeleteMasterEventMutation();
  const [editMaster] = useEditMasterEventMutation();
  //console.log("activity leader---", activityLeaderOptions);

  const validationSchema = object({
    day: number().min(1).max(7).required("Day is required"),
    startDateTime: string().required("Start Time is required"),
    endDateTime: string().required("End Time is required"),
    leaderId: string().required("Activity Leader is required"),
    categoryId: string().required("Category of Event is required"),
    locationId: string().required("Event Location is required"),
    topic: string().required("Event Topic is required"),
    about: string().required("About Event is required"),
    isVirtuallyAllowed: boolean().required(
      "Event VirtuallyAllowed is required"
    ),
  });

  const onSubmit = async (values, onSubmitProps) => {
    //console.log("final values-->", values);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Check if the user's timezone is not New York
    if (userTimeZone !== "America/New_York") {
      alert(
        "You can only perform actions if your timezone is set to New York."
      );
      return;
    }
    const startDateTime1 = moment(values.startDateTime._d).format("X");
    const endDateTime1 = moment(values.endDateTime._d).format("X");
    if (startDateTime1 > endDateTime1) {
      alert("Start Time must be less than End Time");
      return;
    }
    const newValue = {
      ...values,
      startDateTime: startDateTime1,
      endDateTime: endDateTime1,
    };
    //console.log("newValue-->", newValue);
    if (isEditing) {
      try {
        const resp = await editMaster({ masterId, ...newValue }).unwrap();
        if (resp?.status) {
          onSubmitProps.setSubmitting(false);
          navigate("/masters", {
            state: {
              openToast: true,
              toastMsg: resp?.msg,
              severity: "success",
            },
          });
        }
      } catch (err) {
        onSubmitProps.setSubmitting(false);
        console.log("Master edit api err-->", err);
      }
    } else {
      try {
        const resp = await addMaster(newValue).unwrap();
        //console.log('resp---',resp)
        if (resp?.status) {
          onSubmitProps.setSubmitting(false);
          navigate("/masters", {
            state: {
              openToast: true,
              toastMsg: resp?.msg,
              severity: "success",
            },
          });
        }
      } catch (err) {
        onSubmitProps.setSubmitting(false);
        console.log("add master event api err--", err);
      }
    }
  };

  const deleteMasterHandler = async () => {
    try {
      const resp = await deleteMaster(masterId).unwrap();
      if (resp?.status) {
        navigate("/masters", {
          state: {
            openToast: true,
            toastMsg: resp?.msg,
            severity: "success",
          },
        });
      }
    } catch (err) {
      console.log("Delete Master API--->", err);
    }
  };
  useEffect(() => {
    if (masterId) {
      setIsEditing(true);
    }
  }, [masterId]);

  useEffect(() => {
    if (masterId) {
      const getOneMember = async () => {
        try {
          const resp = await getMasterById(masterId).unwrap();
          //console.log("one details---->", resp.events);
          if (resp?.status) {
            const { events } = resp;
            setProfile(events?.leaderId?.profile);
            setMasterData((prevState) => {
              return {
                ...prevState,
                day: events?.day,
                topic: events?.topic,
                about: events?.about,
                isVirtuallyAllowed: events?.isVirtuallyAllowed,
                zoom: events?.zoom,
                categoryId: events?.categoryId._id,
                locationId: events?.locationId?._id,
                leaderId: events?.leaderId?._id,
                startDateTime: moment.unix(events?.startDateTime),
                endDateTime: moment.unix(events?.endDateTime),
              };
            });
          }
        } catch (err) {
          console.log("get one member api err--->", err);
        }
      };
      getOneMember();
    }
  }, [masterId, getMasterById]);
  return (
    <>
      <Grid item md={12}>
        <SettingHeader
          moduleName={isEditing ? "Edit Master Data" : "Add Master Data"}
        />
      </Grid>
      <Grid item md={12}>
        <Formik
          initialValues={masterData}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          <Form>
            <Grid item md={6} container columnSpacing={2} rowSpacing={3}>
              <Grid item md={12}>
                <Box
                  sx={{
                    width: "100px",
                    height: "140px",

                    marginRight: "15px",
                  }}
                >
                  <img
                    alt="profile"
                    src={profile ? profile : Default}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      overflow: "hidden",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item md={12}>
                <AutoCompleteWrapper1
                  name="leaderId"
                  label="Activity Leader"
                  options={isSuccess ? activityLeaderOptions : []}
                  setProfile={setProfile}
                />
              </Grid>
              <Grid item md={4}>
                <TextInputWrapper
                  name="day"
                  label="Day"
                  fullWidth
                  required
                  type="number"
                />
              </Grid>
              <Grid item md={4}>
                <TimeWrapper name="startDateTime" label="Start Time" required />
              </Grid>
              <Grid item md={4}>
                <TimeWrapper name="endDateTime" label="End Time" required />
              </Grid>
              <Grid item md={6}>
                <SelectWrapper
                  name="locationId"
                  label="Event Location"
                  fullWidth
                  select
                  required
                  options={locationOptions || []}
                />
              </Grid>
              <Grid item md={6}>
                <SelectWrapper
                  name="categoryId"
                  label="Category of Event"
                  fullWidth
                  select
                  required
                  options={categoryOptions || []}
                />
              </Grid>
              <Grid item md={6}>
                <TextInputWrapper
                  name="topic"
                  label="Lecture Event Topic"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item md={6}>
                <TextInputWrapper name="zoom" label="Zoom Link" fullWidth />
              </Grid>
              <Grid item md={12}>
                <TextareaWrapper
                  fullWidth
                  name="about"
                  label="About the Event"
                  required
                />
              </Grid>
              <Grid item md={12}>
                <CheckboxWrapper
                  name="isVirtuallyAllowed"
                  label2="Is Virtually Allowed?"
                />
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <SubmitButton value={isEditing ? "Save & Exit" : "Add"} />
                  {isEditing && (
                    <DeleteButton
                      deleteHandler={deleteMasterHandler}
                      isLoading={isLoading}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Grid>
    </>
  );
};
export default AddMaster;
