import {
  FormControl,
  Checkbox,
  OutlinedInput,
  InputLabel,
  ListItemText,
  Select,
  MenuItem,
} from "@mui/material";
const CustomSelect = ({
  name,
  label,
  selectedValue,
  handleChange,
  options,
  isIdToUseInValue
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-checkbox-label13">{label}</InputLabel>
      <Select
        name={name}
        labelId="demo-multiple-checkbox-label13"
        id="demo-multiple-checkbox12"
        multiple
        value={selectedValue}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(", ")}
        // MenuProps={MenuProps}
      >
        {options.map((option) => {
          return (
            <MenuItem
              key={option._id}
              value={
                isIdToUseInValue ? option._id : option.title || option.name
              }
            >
              <Checkbox
                checked={
                  isIdToUseInValue
                    ? selectedValue.indexOf(option._id) > -1
                    : selectedValue.indexOf(option.title || option.name) > -1
                }
              />
              <ListItemText primary={option.title || option.name} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default CustomSelect;
