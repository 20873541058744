import { Grid, Box, TextField, Button} from "@mui/material";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import SettingHeader from "../../components/commonHeader/SettingHeader";
import { object, string, number } from "yup";
import TextInputWrapper from "./../../components/forms/TextInputWrapper";
import DateWrapper from "./../../components/forms/DateWrapper";
import SelectWrapper from "./../../components/forms/SelectWrapper";
import TextareaWrapper from "./../../components/forms/TextareaWrapper";
import UploadButton from "./../../components/forms/UploadButton";
import SubmitButton from "./../../components/forms/SubmitButton";
import {
  useLazyGetCitiesQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
  useGetSettingsQuery,
  useAddMemberMutation,
  useLazyGetMemberByIdQuery,
  useDeleteMemberMutation,
  useEditMemberMutation,
} from "../../apis/member/memberApi";
import CustomSelectWrapper from "../../components/forms/CustomSelectWrapper";
import CheckboxDropDown from "../../components/forms/CheckboxDropDown";
import TextFieldDropDown from "../../components/forms/TextFieldDropDown";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Default from "./../../assets/default.png";
import Toast from "../../components/toast";
import DeleteButton from "../../components/forms/DeleteButton";
import FormLoader from "../../components/loaders/FormLoader";
import ToggleSwitch from "../../components/forms/ToggleSwitch";
import { PrintIcon } from "../../assets/icons";
import Spinner from "../../components/loaders/Spinner";

const initialState = {
  doaCareNumber: "",
  fName: "",
  lName: "",
  dob: null,
  gender: "",
  phone: "",
  email: "",
  maritalStatus: "",
  mType: "",
  employment: "",
  living: "",
  insurance: "",
  language: [],
  education: [],
  zipCode: "",
  apartmentNo: "",
  street: "",
  city: "",
  state: "New York",
  country: "",
  comments: "",
  physicianName: "",
  physicianPhone: "",
  c1Name: "",
  c1Phone: "",
  c1Relationship: "",
  c2Name: "",
  c2Phone: "",
  c2Relationship: "",
  medicalNotes: "",
};
function generateRandomNumber(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
const AddMember = () => {
  const navigate = useNavigate();
  const { memberId } = useParams();

  const [isToastOpen, setIsToastOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [toastMsg, setToastMsg] = useState("");

  const [profile, setProfile] = useState("");
  const [isImageUploading, setIsImageUploading] = useState(false);

  const [accountStatus, setAccountStatus] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [uid, setUid] = useState("");
  const [memberData, setMemberData] = useState(initialState);
  const [stateId, setStateId] = useState(1452); //1452 for New York
  const [cityOptions, setCityOptions] = useState([]);

  const { data: countryOptions } = useGetCountriesQuery();
  const { data: stateOptions } = useGetStatesQuery();
  const [getCities] = useLazyGetCitiesQuery();
  const { data } = useGetSettingsQuery();
  const [addMember] = useAddMemberMutation();
  const [getMemberById, { isLoading: isLoadingOneMemberData }] =
    useLazyGetMemberByIdQuery();
  const [deleteMember, { isLoading: isDeletingMember }] =
    useDeleteMemberMutation();

  const [editMember] = useEditMemberMutation();

  const validationSchema = object({
    fName: string().required("FirstName is required"),
    lName: string().required("LastName is required"),
    email: string()
      .email("Please Enter Valid Email Id")
      .required("Email is required"),
    phone: number().required("Phone is required"),
    dob: string().required("DOB is required"),
    gender: string().required("Gender is required"),
    zipCode: string().required("ZipCode is required"),
    street: string().required("Street is required"),
    city: string().required("City is required"),
    state: string().required("State is required"),
    country: string().required("Country is required"),
    mType: string().required("Membership Type is required"),
  });
  const onSubmit = async (values, onSubmitProps) => {
    //console.log("final member values--->", values);

    const newValue = {
      ...values,
      dob: values?.dob?._d && moment(values.dob._d).unix() * 1,
      profile: profile,
      uid: uid,
    };
    //console.log("final new value", newValue);
    if (isEditing) {
      //console.log('inside...')
      try {
        const resp = await editMember({ memberId, ...newValue }).unwrap();
        if (resp?.status) {
          onSubmitProps.setSubmitting(false);
          navigate("/members", {
            state: {
              openToast: true,
              toastMsg: resp?.msg,
              severity: "success",
            },
          });
        }
      } catch (err) {
        console.log("update member api err", err);
        onSubmitProps.setSubmitting(false);
        if (err?.status == 422) {
          setIsToastOpen(true);
          setToastMsg(err?.data?.errors[0].msg);
          setSeverity("error");
        } else if (err?.status == 400) {
          setIsToastOpen(true);
          setToastMsg(err?.data?.msg);
          setSeverity("error");
        }
      }
    } else {
      try {
        const resp = await addMember(newValue).unwrap();
        if (resp?.status) {
          onSubmitProps.setSubmitting(false);
          navigate("/members", {
            state: {
              openToast: true,
              toastMsg: resp?.msg,
              severity: "success",
            },
          });
        }
      } catch (err) {
        //console.log("member add api err--->", err);
        onSubmitProps.setSubmitting(false);
        if (err?.status == 422) {
          setIsToastOpen(true);
          setToastMsg(err?.data?.errors[0].msg);
          setSeverity("error");
        } else if (err?.status == 400) {
          setIsToastOpen(true);
          setToastMsg(err?.data?.msg);
          setSeverity("error");
        }
      }
    }
  };

  const deleteMemberHandler = async () => {
    try {
      const resp = await deleteMember(memberId).unwrap();
      if (resp?.status) {
        navigate("/members", {
          state: {
            openToast: true,
            toastMsg: resp?.msg,
            severity: "success",
          },
        });
      }
    } catch (err) {}
  };

  const toggleStatusHandler = async (status) => {
    //console.log("status--->", status);
    const newValue = {
      status: status,
    };
    try {
      const resp = await editMember({ memberId, ...newValue }).unwrap();
      if (resp?.status) {
        navigate("/members", {
          state: {
            openToast: true,
            toastMsg: resp?.msg,
            severity: "success",
          },
        });
      }
    } catch (err) {
      console.log("update member api err", err);
    }
  };
  useEffect(() => {
    if (memberId) {
      const getOneMember = async () => {
        try {
          const resp = await getMemberById(memberId).unwrap();
          //console.log("get member by id api-->", resp?.member?.city);
          if (resp.status) {
            setUid(resp?.member?.uid);
            setProfile(resp?.member?.profile || Default);
            if (resp?.member?.status == 3) {
              setAccountStatus(false);
            }
            setMemberData((prevState) => {
              return {
                ...prevState,
                ...resp?.member,
                dob:
                  resp?.member?.dob !== undefined
                    ? moment.unix(resp?.member?.dob)
                    : null,
              };
            });
          }
        } catch (err) {}
      };
      getOneMember();
    }
  }, [memberId, getMemberById]);

  useEffect(() => {
    if (memberId) {
      setIsEditing(true);
    }
  }, [memberId]);

  useEffect(() => {
    if (stateId) {
      const getCityLists = async () => {
        try {
          const resp = await getCities(stateId).unwrap();
          setCityOptions(resp);
        } catch (err) {}
      };
      getCityLists();
    }
  }, [stateId, getCities]);

  useEffect(() => {
    if (!isEditing) {
      const randomNumber = generateRandomNumber(6);
      setUid(randomNumber);
    }
  }, [isEditing]);
  return (
    <>
      {isToastOpen && (
        <Toast
          isOpen={isToastOpen}
          setIsToastOpen={setIsToastOpen}
          severity={severity}
          message={toastMsg}
        />
      )}
      <Grid item md={12}>
        <SettingHeader moduleName={isEditing ? "Edit Member" : "Add Member"} />
      </Grid>
      {isLoadingOneMemberData === true ? (
        <FormLoader />
      ) : (
        <Grid item md={12}>
          <Formik
            enableReinitialize
            initialValues={memberData}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <Grid
                item
                md={12}
                container
                columnSpacing={3}
                rowSpacing={0}
                alignItems="flex-start"
              >
                <Grid item md={6} container columnSpacing={2} rowSpacing={2}>
                  <Grid item md={10} container columnSpacing={2}>
                    <Grid item md={6} container columnSpacing="1px">
                      <Box
                        sx={{
                          width: "100px",
                          height: "140px",

                          marginRight: "10px",
                        }}
                      >
                        {isImageUploading ? (
                          <Spinner />
                        ) : (
                          <img
                            alt="profile"
                            src={profile ? profile : Default}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                            }}
                          />
                        )}
                      </Box>
                      <Grid
                        item
                        md={6}
                        sx={{ alignSelf: "center", justifySelf: "center" }}
                      >
                        <UploadButton
                          setProfile={setProfile}
                          aspectType={1}
                          setIsImageUploading={setIsImageUploading}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      container
                      rowSpacing={2}
                      columnSpacing={1}
                    >
                      <Grid item md={4}>
                        <TextField
                          name="uid"
                          label="IH - UID"
                          value={uid || ""}
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item md={8}>
                        <TextInputWrapper
                          name="doaCareNumber"
                          label="DoA Care Number"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={12}>
                        <SelectWrapper
                          select
                          name="mType"
                          label="Membership Type"
                          required
                          fullWidth
                          options={data?.membershipTypeOptions || []}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="fName"
                      label="First Name"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="lName"
                      label="Last Name"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item md={5}>
                    <DateWrapper name="dob" label="Date of Birth" required />
                  </Grid>
                  <Grid item md={5}>
                    <SelectWrapper
                      name="gender"
                      select
                      label="Gender"
                      required
                      fullWidth
                      options={data?.genderOptions || []}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="phone"
                      label="Phone Number"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="email"
                      label="Email Id"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="zipCode"
                      label="Zip Code"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="apartmentNo"
                      label="Apartment No."
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="street"
                      label="Street Address"
                      fullWidth
                      required
                    />
                  </Grid>

                  <Grid item md={5}>
                    <CustomSelectWrapper
                      select
                      name="country"
                      label="Country"
                      required
                      fullWidth
                      options={countryOptions ? countryOptions : []}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CustomSelectWrapper
                      select
                      name="state"
                      label="State"
                      required
                      fullWidth
                      options={stateOptions ? stateOptions : []}
                      isFetchingState={true}
                      setStateId={setStateId}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CustomSelectWrapper
                      select
                      name="city"
                      label="City"
                      fullWidth
                      required
                      // disabled={isLoading || cityOptions.length == 0}
                      options={cityOptions}
                    />
                  </Grid>
                  <Grid item md={10}>
                    <TextareaWrapper
                      name="comments"
                      label="Additional Comments"
                    />
                  </Grid>
                  <Grid item md={10}>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                      <SubmitButton value={isEditing ? "Save & Exit" : "Add"} />
                      {isEditing && (
                        <DeleteButton
                          deleteHandler={deleteMemberHandler}
                          isLoading={isDeletingMember}
                        />
                      )}
                      {isEditing && (
                        <Button
                          startIcon={<PrintIcon />}
                          variant="contained"
                          size="large"
                          component={Link}
                          to={`/members/print/card/${memberId}`}
                        >
                          Card
                        </Button>
                      )}
                      {isEditing && (
                        <ToggleSwitch
                          isChecked={accountStatus}
                          toggleStatusHandler={toggleStatusHandler}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item md={6} container columnSpacing={2} rowSpacing={2}>
                  <Grid item md={5}>
                    <SelectWrapper
                      name="maritalStatus"
                      label="Marital Status"
                      select
                      fullWidth
                      options={data?.maritalStatusOptions || []}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <SelectWrapper
                      name="employment"
                      label="Employment"
                      select
                      fullWidth
                      options={data?.employmentOptions || []}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextFieldDropDown
                      name="living"
                      label="Living Arrangement"
                      options={data?.livingOptions || []}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextFieldDropDown
                      name="insurance"
                      label="Health Insurance"
                      options={data?.insuranceOptions || []}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CheckboxDropDown
                      name="language"
                      label="Languages Spoken"
                      options={data?.languageOptions || []}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CheckboxDropDown
                      name="education"
                      label="Education"
                      options={data?.educationOptions || []}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="physicianName"
                      label="Primary Care Physician"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="physicianPhone"
                      label="Physician Phone No"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="c1Name"
                      label="Emergency Contact1 Name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="c2Name"
                      label="Emergency Contact2 Name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="c1Phone"
                      label="Emergency Contact1 PhoneNo"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="c2Phone"
                      label="Emergency Contact2 PhoneNo"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="c1Relationship"
                      label="Emergency Contact1 Relationship"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={5}>
                    <TextInputWrapper
                      name="c2Relationship"
                      label="Emergency Contact2 Relationship"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={10}>
                    <TextareaWrapper
                      name="medicalNotes"
                      label="Medical Conditions and Special Notes"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Grid>
      )}
    </>
  );
};

export default AddMember;
