import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useGetMemberByIdQuery } from "../../apis/member/memberApi";
import { PrintIcon } from "../../assets/icons";
import SettingHeader from "../../components/commonHeader/SettingHeader";
import Default from "./../../assets/default.png";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import Logo from "./../../assets/india-bro-logo.png";

const PrintCard = () => {
  const { memberId } = useParams();
  const { data } = useGetMemberByIdQuery(memberId);
  //console.log("print card", data);
  const cardRef1 = useRef(null);
  const cardRef2 = useRef(null);
  const cardRef3 = useRef(null);
  const handlePrintFront = useReactToPrint({
    content: () => cardRef1.current,

    documentTitle: `${data?.member?.fName
      .charAt(0)
      .toUpperCase()}${data?.member?.fName.slice(1)}${data?.member?.lName
      .charAt(0)
      .toUpperCase()}${data?.member?.lName.slice(1)}Card`,
    pageStyle: `@media print {

    
      @page {
        size: 85mm 66mm;
        margin: 0;
      },
      
    }`,
  });
  const handlePrintBack = useReactToPrint({
    content: () => cardRef2.current,
    pageStyle: `@media print {
      @page {
        size: 85mm 65mm;
        margin: 0;
      }
    
    }`,
  });

  const handleFullDetails = useReactToPrint({
    content: () => cardRef3.current,
    documentTitle: `${data?.member?.fName
      .charAt(0)
      .toUpperCase()}${data?.member?.fName.slice(1)}${data?.member?.lName
      .charAt(0)
      .toUpperCase()}${data?.member?.lName.slice(1)}`,
  });

  return (
    <>
      <Grid item md={12}>
        <SettingHeader
          moduleName={`${data?.member?.fName
            .charAt(0)
            .toUpperCase()}${data?.member?.fName.slice(1)} ${data?.member?.lName
            .charAt(0)
            .toUpperCase()}${data?.member?.lName.slice(
            1
          )}  - India Home Print Card`}
        />
      </Grid>
      <Grid item md={12} container columnSpacing={3}>
        <Grid item md={4}>
          <Card
            id="pdf"
            ref={cardRef1}
            sx={{
              boxShadow: "none",
              backgroundColor: "none",
              // width: "630px",
              // height: "400px",
            }}
          >
            <CardContent
              sx={{ display: "flex", gap: "10px", flexDirection: "column" }}
            >
              {/* first box containing logo and text */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ maxWidth: "190px", height: "30px" }}>
                  <img
                    src={Logo}
                    alt="Card Logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      overflow: "hidden",
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="body2">MEMBER ID CARD</Typography>
                </Box>
              </Box>
              {/* second box containing user info */}
              <Box sx={{ display: "flex", gap: "30px" }}>
                <Box>
                  <Avatar
                    src={
                      data?.member?.profile ? data?.member?.profile : Default
                    }
                    alt="MemberPicCard"
                    sx={{ width: 90, height: 90 }}
                  />
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                      {`${data?.member?.fName
                        .charAt(0)
                        .toUpperCase()}${data?.member?.fName.slice(1)} `}{" "}
                      {`${data?.member?.lName
                        .charAt(0)
                        .toUpperCase()}${data?.member?.lName.slice(1)} `}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      Contact No. : {data?.member?.phone}{" "}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="body2">Emergency Contact:</Typography>
                    <Typography variant="body2">
                      Name: {data?.member?.c1Name} (
                      {data?.member?.c1Relationship || "N/A"})
                    </Typography>
                    <Typography variant="body2">
                      Contact Number: {data?.member?.c1Phone || "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* third box containing india web url and phone */}
              <Box sx={{ alignSelf: "center" }}>
                <Divider />
                <Typography sx={{ mt: "8px" }} variant="body2">
                  www.indiahome.org | 917-288-7600
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={2}>
          <Button
            startIcon={<PrintIcon />}
            variant="contained"
            size="large"
            onClick={handlePrintFront}
          >
            Print Front
          </Button>
        </Grid>
      </Grid>

      {/* print back */}
      <Grid item md={12} container columnSpacing={3}>
        <Grid item md={4}>
          <Card
            ref={cardRef2}
            sx={{ boxShadow: "none", backgroundColor: "none" }}
          >
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              {/* first box containing logo and text */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ maxWidth: "190px", height: "30px" }}>
                  <img
                    src={Logo}
                    alt="Card Logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      overflow: "hidden",
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    {`${data?.member?.fName
                      .charAt(0)
                      .toUpperCase()}${data?.member?.fName.slice(1)} `}{" "}
                    {`${data?.member?.lName
                      .charAt(0)
                      .toUpperCase()}${data?.member?.lName.slice(1)} `}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{ height: "150px", width: `150px`, alignSelf: "center" }}
              >
                <img
                  alt="qrcode"
                  src={data?.member?.qrCodeImageUrl}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={2}>
          <Button
            startIcon={<PrintIcon />}
            variant="contained"
            size="large"
            onClick={handlePrintBack}
          >
            Print Back
          </Button>
        </Grid>
      </Grid>
      {/* full details */}
      <Grid item md={12} container columnSpacing={3}>
        <Grid item md={4}>
          <Card
            ref={cardRef3}
            sx={{ backgroundColor: "none", boxShadow: "none" }}
          >
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "85px",

                  marginRight: "15px",
                  marginBottom: "1rem",
                }}
              >
                <img
                  alt="profile"
                  src={data?.member?.profile ? data?.member?.profile : Default}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    overflow: "hidden",
                  }}
                />
              </Box>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                UID: {data?.member?.uid}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                DoA Care Number: {data?.member?.doaCareNumber}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Membership: {data?.member?.mType}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                First Name:
                {`${data?.member?.fName
                  .charAt(0)
                  .toUpperCase()}${data?.member?.fName.slice(1)} `}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Last Name:
                {`${data?.member?.lName
                  .charAt(0)
                  .toUpperCase()}${data?.member?.lName.slice(1)} `}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Gender: {data?.member?.gender}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Phone: {data?.member?.phone}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Email: {data?.member?.email}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                DOB: {moment.unix(data?.member?.dob).format("MM/DD/YYYY")}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Zip: {data?.member?.zipCode}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Apartment: {data?.member?.apartmentNo}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Street: {data?.member?.street}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Country: {data?.member?.country}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                State: {data?.member?.state}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                City: {data?.member?.city}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Comments: {data?.member?.comments}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Marital Status: {data?.member?.maritalStatus}
              </Typography>

              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Employment: {data?.member?.employment}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Insurance: {data?.member?.insurance}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Education: {data?.member?.education}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Living: {data?.member?.living}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Physician Name: {data?.member?.physicianName}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Physician Phone: {data?.member?.physicianPhone}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Emergency Name: {data?.member?.c1Name}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Emergency Phone: {data?.member?.c1Phone}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Emergency Relationship: {data?.member?.c1Relationship}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Medical Notes: {data?.member?.medicalNotes}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={2}>
          <Button
            startIcon={<PrintIcon />}
            variant="contained"
            size="large"
            onClick={handleFullDetails}
          >
            Print Full
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PrintCard;
