import FilteredReport from "./FilteredReport";
import MacroReport from "./MacroReport";

const SessionReportContainer = () => {
  return (
    <>
      <MacroReport />
      <FilteredReport />
    </>
  );
};

export default SessionReportContainer;
