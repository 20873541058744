import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

import { useState } from "react";
import { ArrowBackIcon } from "./../../assets/icons";
import { useGetCommonLogsQuery } from "../../apis/log/logApi";

const CommonLog = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  let logFor;
  const { logsFor } = useParams();
  if (logsFor == "member") {
    logFor = 1;
  } else if (logsFor == "staff") {
    logFor = 2;
  } else if (logsFor == "session") {
    logFor = 3;
  } else if (logsFor == "master") {
    logFor = 4;
  }

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { data, isLoading } = useGetCommonLogsQuery({ page, limit, logFor });
  //console.log(" CommonLogsAPI-->", data);

  const columns = [
    {
      field: "dateTime",
      headerName: "Date",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        //return moment.unix(params.row.dateTime).format("DD/MM/YY");
        return params.row.logDate;
      },
    },
    {
      field: "dateTime2",
      headerName: "Time",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        //return moment.unix(params.row.dateTime).format("hh:mm A");
        return params.row.logTime;
      },
    },
    {
      field: "actionDoneOn",
      headerName: "Action On",
      flex: 1,
      renderCell: (params) => {
        let actionOn;
        if (logFor === 3) {
          actionOn = params.row.actionDoneOn?.topic;
        } else if (logFor === 4) {
          actionOn = "Master Calendar";
        } else {
          actionOn =
            params.row.actionDoneOn?.fName[0]?.toUpperCase() +
            params.row.actionDoneOn?.fName?.slice(1);
        }
        return actionOn;
      },
    },
    {
      field: "actionDoneBy",
      headerName: "Action By",
      flex: 1,
      renderCell: (params) => {
        return `${
          params.row.actionDoneBy.fName[0].toUpperCase() +
          params.row.actionDoneBy.fName.slice(1)
        }`;
      },
    },
    {
      field: "typeOfAction",
      headerName: "Action",
      flex: 1,
    },
    {
      field: "ip",
      headerName: "IP",
      flex: 1,
    },
    {
      field: "machine",
      headerName: "Machine",
      flex: 1,
    },
    {
      field: "timezone",
      headerName: "TimeZone",
      flex: 1,
    },
  ];
  return (
    <>
      <Grid item md={12}>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(5),
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => {
              navigate(-1);
            }}
            sx={{ cursor: "pointer" }}
          >
            <ArrowBackIcon sx={{ color: theme.palette.primary.main }} />
          </Box>

          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            {`${logsFor.charAt(0).toUpperCase()}${logsFor.slice(1)} Logs`}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={12}>
        <DataGrid
          autoHeight={true}
          getRowId={(row) => {
            return row._id;
          }}
          rows={(data && data?.logs) || []}
          rowCount={(data && data.totalRec) || 0}
          loading={isLoading || !data}
          rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
          pagination
          page={page}
          pageSize={limit}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
          columns={columns}
        />
      </Grid>
    </>
  );
};

export default CommonLog;
