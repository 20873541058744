import { Alert, Snackbar } from "@mui/material";
const Toast = ({ isOpen, severity, message, setIsToastOpen }) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={() => {
        setIsToastOpen(false);
      }}
    >
      <Alert severity={severity} sx={{ width: "100%", fontSize: "1.2rem" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
