import baseApi from "../baseApi";

const memberApi = baseApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getAllMembers: builder.query({
        providesTags: ["Member"],
        query: ({ page, limit, searchKeyword }) => {
          return {
            url: "/members",
            method: "GET",
            params: {
              page,
              limit,
              searchKeyword,
            },
          };
        },
      }),
      getCountries: builder.query({
        query: () => {
          return {
            url: "/countries",
            method: "GET",
          };
        },
        transformResponse: (response) => {
          //console.log("before transform country options-->", response);
          const countryOptions = [];
          if (response?.status) {
            for (let el of response?.countries) {
              countryOptions.push({
                id: el.id,
                value: el.name,
              });
            }
          }
          return countryOptions;
        },
      }),
      getStates: builder.query({
        query: () => {
          return {
            url: `/states/233`,
            method: "GET",
          };
        },
        transformResponse: (response) => {
          //console.log('before transform',response)
          const stateOptions = [];
          if (response.status) {
            for (let el of response?.states) {
              stateOptions.push({
                id: el.id,
                value: el.name,
              });
            }
          }
          return stateOptions;
        },
      }),
      getCities: builder.query({
        query: (stateId) => {
          return {
            url: `/cities/${stateId}`,
            method: "GET",
          };
        },
        transformResponse: (response) => {
          const cityOptions = [];
          if (response?.status) {
            response?.cities.map((city) => {
              return cityOptions.push({
                id: city._id,
                value: city.name,
              });
            });
          }
          return cityOptions;
        },
      }),
      getSettings: builder.query({
        query: () => {
          return {
            url: "/membersetting",
            method: "GET",
          };
        },
        transformResponse: (response) => {
          //console.log("before transfrom", response);
          const finalObj = {};
          if (response?.status) {
            // gender
            const { items: genderOptions } = response.settings.find((el) => {
              return el._id == 1;
            });
            const newGenderOptions = genderOptions.map((el) => {
              return {
                _id: el.title,
                title: el.title,
                isOther: el.isOther,
              };
            });
            // MembershipType
            const { items: membershipTypeOptions } = response.settings.find(
              (el) => {
                return el._id == 2;
              }
            );
            const newMembershipOptions = membershipTypeOptions.map((el) => {
              return {
                _id: el.title,
                title: el.title,
                isOther: el.isOther,
              };
            });
            // marital status
            const { items: maritalStatusOptions } = response.settings.find(
              (el) => {
                return el._id == 3;
              }
            );
            const newMaritalStatusOptions = maritalStatusOptions.map((el) => {
              return {
                _id: el.title,
                title: el.title,
                isOther: el.isOther,
              };
            });
            //employment
            const { items: employmentOptions } = response.settings.find(
              (el) => {
                return el._id == 4;
              }
            );
            const newEmploymentOptions = employmentOptions.map((el) => {
              return {
                _id: el.title,
                title: el.title,
                isOther: el.isOther,
              };
            });
            //living arrangements
            const { items: livingOptions } = response.settings.find((el) => {
              return el._id == 5;
            });
            const newLivingOptions = livingOptions.map((el) => {
              return {
                _id: el.title,
                title: el.title,
                isOther: el.isOther,
              };
            });
            //health insurance
            const { items: insuranceOptions } = response.settings.find((el) => {
              return el._id == 6;
            });
            const newInsuranceOptions = insuranceOptions.map((el) => {
              return {
                _id: el.title,
                title: el.title,
                isOther: el.isOther,
              };
            });
            //language spoken
            const { items: languageOptions } = response.settings.find((el) => {
              return el._id == 7;
            });
            const newLanguageOptions = languageOptions.map((el) => {
              return {
                _id: el.title,
                title: el.title,
                isOther: el.isOther,
              };
            });
            //education
            const { items: educationOptions } = response.settings.find((el) => {
              return el._id == 8;
            });
            const newEducationOptions = educationOptions.map((el) => {
              return {
                _id: el.title,
                title: el.title,
                isOther: el.isOther,
              };
            });
            finalObj.genderOptions = [...newGenderOptions];
            finalObj.membershipTypeOptions = [...newMembershipOptions];
            finalObj.maritalStatusOptions = [...newMaritalStatusOptions];
            finalObj.employmentOptions = [...newEmploymentOptions];
            finalObj.languageOptions = [...newLanguageOptions];
            finalObj.educationOptions = [...newEducationOptions];
            finalObj.livingOptions = [...newLivingOptions];
            finalObj.insuranceOptions = [...newInsuranceOptions];
            //console.log("finalObj", finalObj);
          }
          //console.log("finalObj", finalObj);
          return finalObj;
        },
      }),
      addMember: builder.mutation({
        invalidatesTags: ["Member"],
        query: (memberData) => {
          return {
            url: "/member",
            method: "POST",
            body: memberData,
          };
        },
      }),
      getMemberById: builder.query({
        query: (memberId) => {
          return {
            url: `/member/${memberId}`,
            method: "GET",
          };
        },
      }),
      deleteMember: builder.mutation({
        invalidatesTags: ["Member"],
        query: (memberId) => {
          return {
            url: `/member/${memberId}`,
            method: "DELETE",
          };
        },
      }),
      editMember: builder.mutation({
        invalidatesTags: ["Member"],
        query: ({ memberId, ...memberData }) => {
          return {
            url: `/member/${memberId}`,
            method: "PATCH",
            body: memberData,
          };
        },
      }),
    };
  },
});

export const {
  useGetAllMembersQuery,
  useLazyGetAllMembersQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
  useLazyGetCitiesQuery,
  useGetSettingsQuery,
  useAddMemberMutation,
  useLazyGetMemberByIdQuery,
  useDeleteMemberMutation,
  useEditMemberMutation,
  useGetMemberByIdQuery,
} = memberApi;
