export const addAdminToLocalStorage = (admin) => {
  localStorage.setItem("admin", JSON.stringify(admin));
};

export const addTokenToLocalStorage = (token) => {
  localStorage.setItem("token", JSON.stringify(token));
};

export const getAdminFromLocalStorage = () => {
  const data = localStorage.getItem("admin");
  const admin = data ? JSON.parse(data) : null;
  return admin;
};

export const getTokenFromLocalStorage = () => {
  const data = localStorage.getItem("token");
  const token = data ? JSON.parse(data) : null;
  return token;
};

export const deleteAdminFromLocalStorage = () => {
   localStorage.removeItem("admin");
   localStorage.removeItem("token");
};


