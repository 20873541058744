import Cards from "../../components/staff/Cards";

const Staff = () => {
  return (
    <>
      <Cards />
    </>
  );
};

export default Staff;
