import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Popover } from "@mui/material";

const Calendar = ({ anchorEl, setAnchorEl, setPublishDate }) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => {
        setAnchorEl(null);
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateCalendar onChange={(newValue) => {
          setAnchorEl(null);
          setPublishDate(newValue);
        }} />
      </LocalizationProvider>
    </Popover>
  );
};
export default Calendar;
