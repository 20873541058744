import LoadingButton from "@mui/lab/LoadingButton";
import { useFormikContext } from "formik";
import SendIcon from "@mui/icons-material/Send";
const SubmitButton = ({ value }) => {
  const { submitForm, isSubmitting } = useFormikContext();
  const handleSubmit = () => {
    submitForm();
  };

  return (
    <LoadingButton
      startIcon={<SendIcon />}
      variant="contained"
      size="large"
      onClick={handleSubmit}
      loading={isSubmitting}
    >
      {value}
    </LoadingButton>
  );
};

export default SubmitButton;
