import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";

import React from "react";

const AreYouSure = ({
  title,
  isDialogOpen,
  setIsDialogOpen,
  isLoading,
  deleteHandler,
}) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={isDialogOpen}
      onClose={() => {
        setIsDialogOpen(false);
      }}
    >
      <DialogTitle>
        <Typography variant="body1" color="primary" sx={{ fontWeight: 700 }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button
          startIcon={<ClearIcon />}
          variant="contained"
          onClick={() => {
            setIsDialogOpen(false);
          }}
        >
          NO
        </Button>
        <LoadingButton
          startIcon={<CheckIcon />}
          variant="contained"
          loading={isLoading}
          onClick={deleteHandler}
        >
          YES
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default AreYouSure;
