import { Button, IconButton } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useState } from "react";
import Cropper from "../modal/Cropper";

const UploadButton = ({ setProfile, aspectType, setIsImageUploading }) => {
  const [image, setImage] = useState(null);
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const handleImageChange = (e) => {
    //console.log("about event", e.target.files);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.addEventListener("load", () => {
      setImage(reader.result);
      setIsCropperOpen(true);
    });
  };

  const setProfilePic = (url) => {
    // console.log("setImageInFormik", url);
    setProfile(url);
    setIsImageUploading(false);
  };

  //console.log("upladed image", file);
  return (
    <>
      {isCropperOpen && (
        <Cropper
          isCropperOpen={isCropperOpen}
          setIsCropperOpen={setIsCropperOpen}
          imageSrc={image}
          setProfilePic={setProfilePic}
          aspectType={aspectType}
          setIsImageUploading={setIsImageUploading}
        />
      )}
      <Button variant="contained" component="label">
        Upload
        <input
          hidden
          accept="image/*"
          multiple
          type="file"
          onChange={(e) => {
            handleImageChange(e);
          }}
        />
      </Button>
      <IconButton color="primary" component="label">
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={(e) => {
            handleImageChange(e);
          }}
        />
        <PhotoCamera />
      </IconButton>
    </>
  );
};

export default UploadButton;
