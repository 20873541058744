import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetAllSessionQuery } from "../../apis/event/eventApi";
import CommonCard from "../../components/card";
import ModuleHeader from "../../components/commonHeader/ModuleHeader";
import CustomSkeleton from "../../components/loaders/CustomSkeleton";
import NoRecords from "../../components/norecords/NoRecords";
import CommonPagination from "../../components/pagination/CommonPagination";
import Toast from "../../components/toast";
import moment from "moment";

const EventContainer = () => {
  const location = useLocation();
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { data, isFetching, isLoading } = useGetAllSessionQuery({
    page,
    searchKeyword,
    startDate,
    endDate,
  });

  const handleDateChange = (newValue) => {
    //console.log("newValue---", newValue);
    const startDate = newValue?._d && moment(newValue?._d).unix() * 1;
    const endDate = moment(newValue?._d).endOf("day").format("X") * 1;
    //console.log("seee", startDate, endDate);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    setIsToastOpen(location?.state?.openToast);
    setSeverity(location?.state?.severity);
    setMessage(location?.state?.toastMsg);
  }, [location?.state]);

  const uiLoader = () => {
    if (isFetching && isLoading) {
      return <CustomSkeleton />;
    } else if (data?.events?.length === 0) {
      return <NoRecords />;
    } else if (data?.events?.length > 0) {
      return data?.events?.map((el) => {
        const activityLeaderName = `${el?.leaderId?.fName
          .charAt(0)
          .toUpperCase()}${el?.leaderId?.fName.slice(1)}`;
        return (
          <Grid item lg={3} md={6} sm={12} xs={12} key={el._id} event={el}>
            <CommonCard
              profilePic={el?.leaderId?.profile}
              to={`/sessions/edit/${el._id}`}
              cardContent={[
                el?.topic,
                activityLeaderName,
                el?.Date,
                `${el?.startTime} - ${el?.endTime}`,
                el?.locationId?.name,
              ]}
            />
          </Grid>
        );
      });
    }
  };
  return (
    <>
      {isToastOpen && (
        <Toast
          isOpen={isToastOpen}
          setIsToastOpen={setIsToastOpen}
          message={message}
          severity={severity}
        />
      )}
      <Grid item md={12}>
        <ModuleHeader
          moduleName="Sessions Calendar"
          buttonName="Add Session"
          label="Search by topic"
          settingTo="/sessions"
          to="/sessions/add"
          logsTo="/sessions/logs/session"
          isLogsIconVisible={true}
          isCalendarVisible={true}
          setSearchKeyword={setSearchKeyword}
          handleDateChange={handleDateChange}
        />
      </Grid>
      {uiLoader()}
      {data?.events?.length > 0 && (
        <Grid item md={12} sx={{ mt: "7rem" }}>
          <CommonPagination
            page={page}
            totalPage={data?.totalPage}
            setPage={setPage}
          />
        </Grid>
      )}
    </>
  );
};
export default EventContainer;
