import { TextField } from "@mui/material";
import { useField } from "formik";

const TextareaWrapper = ({ name, ...rest }) => {
  const [field, meta] = useField(name);
  const config = {
    ...field,
    ...rest,
    variant: "outlined",
    rows: 4,
  };
  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return <TextField {...config} fullWidth multiline />;
};

export default TextareaWrapper;
