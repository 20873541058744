import { Grid, Box, Button } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  useGetAllActivityLeaderQuery,
  useGetAllEventLocationsQuery,
  useGetAllEventCatQuery,
} from "../../apis/master/masterApi";
import TextInputWrapper from "./../../components/forms/TextInputWrapper";
import SelectWrapper from "./../../components/forms/SelectWrapper";
import TextareaWrapper from "./../../components/forms/TextareaWrapper";
import TimeWrapper from "../../components/forms/TimeWrapper";
import CheckboxWrapper from "./../../components/forms/CheckboxWrapper";
import SubmitButton from "./../../components/forms/SubmitButton";
import SettingHeader from "../../components/commonHeader/SettingHeader";
import AutoCompleteWrapper1 from "../../components/forms/AutoCompleteWrapper1";
import DateWrapper from "./../../components/forms/DateWrapper";
import Default from "./../../assets/default.png";
import { object, string } from "yup";
import moment from "moment";
import {
  useAddSessionMutation,
  useDeleteSessionMutation,
  useEditSessionMutation,
  useLazyGetSessionByIdQuery,
} from "../../apis/event/eventApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import DeleteButton from "../../components/forms/DeleteButton";
import { FormatListBulletedIcon } from "../../assets/icons";

const initialState = {
  date: null,
  startDateTime: null,
  endDateTime: null,
  leaderId: null,
  categoryId: "",
  locationId: "",
  topic: "",
  about: "",
  zoom: "",
  isVirtuallyAllowed: false,
};

const AddEvent = () => {
  const navigate = useNavigate();

  const { sessionId } = useParams();
  const [profile, setProfile] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [eventData, setEventData] = useState(initialState);

  const { data: activityLeaderOptions, isSuccess } =
    useGetAllActivityLeaderQuery();
  const { data: locationOptions } = useGetAllEventLocationsQuery();
  const { data: categoryOptions } = useGetAllEventCatQuery();
  const [addSession] = useAddSessionMutation();
  const [getSessionById] = useLazyGetSessionByIdQuery();
  const [deleteSession, { isLoading }] = useDeleteSessionMutation();
  const [editSession] = useEditSessionMutation();

  const validationSchema = object({
    date: string().required("Date is required"),
    startDateTime: string().required("Start Time is required"),
    endDateTime: string().required("End Time is required"),
    leaderId: string().required("Activity Leader is required"),
    categoryId: string().required("Category of Event is required"),
    locationId: string().required("Event Location is required"),
    topic: string().required("Event Topic is required"),
    about: string().required("About Event is required"),
  });

  const onSubmit = async (values, onSubmitProps) => {
    //console.log("final values--->", values);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Check if the user's timezone is not New York
    if (userTimeZone !== "America/New_York") {
      alert(
        "You can only perform actions if your timezone is set to New York."
      );
      return;
    }
    const date = moment.unix(values?.date?._d).startOf("day").format("X");
    const startDateTime1 = moment.unix(values?.startDateTime?._d).format("X");
    const endDateTime1 = moment.unix(values?.endDateTime?._d).format("X");
    const updatedStartDateTime = moment(date * 1)
      .add({
        hours: moment(startDateTime1 * 1).hours(),
        minutes: moment(startDateTime1 * 1).minutes(),
      })
      .add(1, "minute")
      .format("X");
    const updatedEndDateTime = moment(date * 1)
      .add({
        hours: moment(endDateTime1 * 1).hours(),
        minutes: moment(endDateTime1 * 1).minutes(),
      })
      .add(1, "minute")
      .format("X");
    //console.log("date---->", date);
    //console.log(updatedStartDateTime, updatedEndDateTime);
    if (updatedStartDateTime > updatedEndDateTime) {
      alert("Start Time must be less than End Time");
      return;
    }
    const newValue = {
      ...values,
      startDateTime: updatedStartDateTime,
      endDateTime: updatedEndDateTime,
    };
    // console.log("new values", newValue);

    if (isEditing) {
      try {
        const resp = await editSession({ sessionId, ...newValue }).unwrap();
        if (resp?.status) {
          onSubmitProps.setSubmitting(false);
          navigate("/sessions", {
            state: {
              openToast: true,
              toastMsg: resp?.msg,
              severity: "success",
            },
          });
        }
      } catch (err) {
        onSubmitProps.setSubmitting(false);
        console.log("event edit api err----", err);
      }
    } else {
      try {
        const resp = await addSession(newValue).unwrap();
        if (resp?.status) {
          onSubmitProps.setSubmitting(false);
          navigate("/sessions", {
            state: {
              openToast: true,
              toastMsg: resp?.msg,
              severity: "success",
            },
          });
        }
      } catch (err) {
        onSubmitProps.setSubmitting(false);
        console.log("add event api err-->", err);
      }
    }
  };

  const deleteHandler = async () => {
    try {
      const resp = await deleteSession(sessionId).unwrap();
      if (resp?.status) {
        navigate("/sessions", {
          state: {
            openToast: true,
            toastMsg: resp?.msg,
            severity: "success",
          },
        });
      }
    } catch (err) {
      console.log("delete api err--->", err);
    }
  };
  useEffect(() => {
    if (sessionId) {
      setIsEditing(true);
    }
  }, [sessionId]);

  useEffect(() => {
    if (sessionId) {
      const getOneSession = async () => {
        try {
          const resp = await getSessionById(sessionId).unwrap();
          //console.log("one session resp---", resp);
          if (resp?.status) {
            setProfile(resp?.event?.leaderId?.profile);

            setEventData((prevState) => {
              return {
                ...prevState,
                categoryId: resp?.event?.categoryId?._id,
                locationId: resp?.event?.locationId?._id,
                topic: resp?.event?.topic,
                about: resp?.event?.about,
                zoom: resp?.event?.zoom,
                isVirtuallyAllowed: resp?.event?.isVirtuallyAllowed,
                date: moment.unix(resp?.event?.startDateTime).startOf("day"),
                startDateTime: moment.unix(resp?.event?.startDateTime),
                endDateTime: moment.unix(resp?.event?.endDateTime),
                leaderId: resp?.event?.leaderId?._id,
              };
            });
          }
        } catch (err) {
          console.log("get session by id api err-->", err);
        }
      };
      getOneSession();
    }
  }, [sessionId, getSessionById]);

  return (
    <>
      <Grid item md={12}>
        <SettingHeader
          moduleName={isEditing ? "Edit Session" : "Add Session"}
        />
      </Grid>
      <Grid item md={12}>
        <Formik
          enableReinitialize
          initialValues={eventData}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values }) => (
            <Form>
              <Grid item md={6} container columnSpacing={2} rowSpacing={3}>
                <Grid item md={12}>
                  <Box
                    sx={{
                      width: "100px",
                      height: "140px",

                      marginRight: "15px",
                    }}
                  >
                    <img
                      alt="profile"
                      src={profile ? profile : Default}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        overflow: "hidden",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <AutoCompleteWrapper1
                    name="leaderId"
                    label="Activity Leader"
                    options={isSuccess ? activityLeaderOptions : []}
                    setProfile={setProfile}
                  />
                </Grid>
                <Grid item md={4}>
                  <DateWrapper name="date" label="Date" />
                </Grid>
                <Grid item md={4}>
                  <TimeWrapper
                    name="startDateTime"
                    label="Start Time"
                    required
                  />
                </Grid>
                <Grid item md={4}>
                  <TimeWrapper name="endDateTime" label="End Time" required />
                </Grid>
                <Grid item md={6}>
                  <SelectWrapper
                    name="locationId"
                    label="Event Location"
                    fullWidth
                    select
                    required
                    options={locationOptions || []}
                  />
                </Grid>
                <Grid item md={6}>
                  <SelectWrapper
                    name="categoryId"
                    label="Category of Event"
                    fullWidth
                    select
                    required
                    options={categoryOptions || []}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextInputWrapper
                    name="topic"
                    label="Lecture Event Topic"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  <TextInputWrapper name="zoom" label="Zoom Link" fullWidth />
                </Grid>
                <Grid item md={12}>
                  <TextareaWrapper
                    fullWidth
                    name="about"
                    label="About the Event"
                    required
                  />
                </Grid>
                <Grid item md={12}>
                  <CheckboxWrapper
                    name="isVirtuallyAllowed"
                    nameZoom="zoom"
                    label2="Is Virtually Allowed?"
                  />
                </Grid>
                <Grid item md={12}>
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <SubmitButton value={isEditing ? "Save & Exit" : "Add"} />
                    {isEditing && (
                      <DeleteButton
                        deleteHandler={deleteHandler}
                        isLoading={isLoading}
                      />
                    )}
                    {isEditing && (
                      <Button
                        startIcon={<FormatListBulletedIcon />}
                        variant="contained"
                        component={Link}
                        to={`/sessions/rsvp/${sessionId}`}
                      >
                        RSVP
                      </Button>
                    )}
                    {isEditing && (
                      <Button
                        startIcon={<FormatListBulletedIcon />}
                        variant="contained"
                        component={Link}
                        to={`/sessions/attendees/${sessionId}`}
                      >
                        ATTENDEES
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default AddEvent;
