import {
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Avatar,
} from "@mui/material";
import Default from "./../../assets/default.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router";
import TextInputWrapper from "../../components/forms/TextInputWrapper";
import SubmitButton from "../../components/forms/SubmitButton";
import DateWrapper from "../../components/forms/DateWrapper";
import TextareaWrapper from "../../components/forms/TextareaWrapper";
import SelectWrapper from "../../components/forms/SelectWrapper";
import CheckboxWrapper from "../../components/forms/CheckboxWrapper";
import {
  useGetGenderQuery,
  useGetAllRolesQuery,
  useAddStaffMutation,
  useDeleteStaffMutation,
  useEditStaffMutation,
  useLazyGetStaffByIdQuery,
} from "../../apis/staff/staffApi";
import moment from "moment";
import Toast from "../../components/toast";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import AreYouSure from "../../components/modal/AreYouSure";
import DeleteIcon from "@mui/icons-material/Delete";
import FormLoader from "../../components/loaders/FormLoader";
import UploadButton from "./../../components/forms/UploadButton";
import Spinner from "../../components/loaders/Spinner";
const AddStaff = () => {
  const navigate = useNavigate();
  const { staffId } = useParams();
  const theme = useTheme();
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastErrMsg, setToastErrMsg] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data: genderOptions } = useGetGenderQuery();
  const { data: roleOptions } = useGetAllRolesQuery();
  const [addStaff] = useAddStaffMutation();
  const [deleteStaff, { isLoading, isSuccess }] = useDeleteStaffMutation();
  const [editStaff] = useEditStaffMutation();
  const [getStaffById, { isLoading: isStaffLoading }] =
    useLazyGetStaffByIdQuery();
  //console.log("oneStaff Data Api--->", staff);
  const [profile, setProfile] = useState("");
  const [isImageUploading, setIsImageUploading] = useState(false);

  const initialState = {
    fName: "",
    lName: "",
    dob: null,
    phone: "",
    email: "",
    gender: "",
    address: "",
    roleId: "",
    isActivityLeader: false,
  };
  const [staffData, setStaffData] = useState(initialState);
  const validationSchema = yup.object({
    fName: yup.string().required("FirstName is required"),
    lName: yup
      .string()
      .optional()
      .matches(/^[a-zA-Z ]+$/, "Numbers are not allowed in LastName"),
    phone: yup.string().required("Phone Number is required"),
    email: yup
      .string()
      .email("Invalid EmailId")
      .required("EmailId is required"),
    gender: yup.string().required("Gender is required"),
    roleId: yup.string().required("Role is required"),
  });

  const onSubmit = async (values, onSubmitProps) => {
    const newValue = {
      ...values,
      dob: values?.dob?._d && moment(values.dob._d).unix() * 1,
      profile: profile,
    };

    if (staffId) {
      // mean we will update
      try {
        const resp = await editStaff({ staffId, ...newValue }).unwrap();
        //console.log("staffedit resp", resp);
        if (resp?.status) {
          onSubmitProps.setSubmitting(false);
          navigate("/staffs", {
            state: {
              openToast: true,
              toastMsg: resp?.msg,
              severity: "success",
            },
          });
        }
      } catch (err) {
        console.log("StaffEditAPI ERR---->", err);
        const { data } = err;
        setIsToastOpen(true);
        setToastErrMsg(data?.msg || "Something Went Wrong");
      }
    } else {
      // means we will add
      try {
        const resp = await addStaff(newValue).unwrap();

        if (resp?.status) {
          onSubmitProps.setSubmitting(false);
          navigate("/staffs", {
            state: {
              openToast: true,
              toastMsg: resp?.msg,
              severity: "success",
            },
          });
        }
      } catch (err) {
        console.log("staff API ERR", err);
        const { data } = err;
        setIsToastOpen(true);
        setToastErrMsg(data?.msg || "Something Went Wrong");
      }
    }
  };

  useEffect(() => {
    if (staffId) {
      const getStaffOneData = async () => {
        try {
          const { staff } = await getStaffById(staffId).unwrap();
          //console.log("oneStaffData-->", staff);
          setProfile(staff?.profile || Default);
          setStaffData((prevStaff) => {
            return {
              ...prevStaff,
              fName: staff.fName || "",
              lName: staff.lName || "",
              dob: staff?.dob !== undefined ? moment.unix(staff?.dob) : null,
              phone: staff.phone || "",
              email: staff.email || "",
              gender: staff?.gender?._id || "",
              address: staff?.address || "",
              roleId: staff?.roleId?._id || "",
              isActivityLeader: staff?.isActivityLeader || false,
            };
          });
        } catch (err) {
          console.log("StaffOneAPIERR-->", err);
        }
      };
      getStaffOneData();
    }
  }, [staffId, getStaffById]);
  //console.log("datmoment", moment.unix(staff?.staff?.dob));
  //console.log("staffData", staffData);

  const deleteStaffHandler = async () => {
    try {
      const resp = await deleteStaff(staffId).unwrap();
      //console.log("StaffDelete API resp-->", resp);
      if (resp.status) {
        navigate("/staffs", {
          state: {
            openToast: true,
            toastMsg: resp?.msg,
            severity: "success",
          },
        });
        setIsDialogOpen(false);
      }
    } catch (err) {
      console.log("staffDelete API ERR-->", err);
    }
  };
  const uiLoader = () => {
    if (isStaffLoading) {
      return <FormLoader />;
    } else {
      return (
        <Grid item md={12} container rowGap={9}>
          <AreYouSure
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
            title={`Are you sure you would like to delete?`}
            isLoading={isLoading}
            isSuccess={isSuccess}
            deleteHandler={deleteStaffHandler}
          />
          <Toast
            isOpen={isToastOpen}
            setIsToastOpen={setIsToastOpen}
            severity="error"
            message={toastErrMsg}
          />
          <Grid item md={12} sm={12}>
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(5),
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
                sx={{ cursor: "pointer" }}
              >
                <ArrowBackIcon />
              </IconButton>

              <Typography variant="h5" sx={{ fontWeight: 700 }}>
                {staffId !== undefined ? "Edit Staff" : "Add Staff"}
              </Typography>
            </Box>
          </Grid>
          <Formik
            enableReinitialize
            initialValues={staffData}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <Grid item md={12} container rowGap={2} columnGap={4}>
                {/* understand grid from this no flex used */}
                <Grid item md={12} columnGap={4} container>
                  <Grid item md={1}>
                    {isImageUploading ? (
                      <Spinner />
                    ) : (
                      <Avatar
                        src={profile ? profile : Default}
                        title="profilePic"
                        sx={{ width: 120, height: 120, objectFit: "cover" }}
                      />
                    )}
                  </Grid>
                  {/* check this css property */}
                  <Grid item md={2} justifyContent="center" alignSelf="center">
                    <UploadButton
                      setProfile={setProfile}
                      aspectType={1}
                      setIsImageUploading={setIsImageUploading}
                    />
                  </Grid>
                </Grid>

                <Grid item md={4}>
                  <TextInputWrapper
                    name="fName"
                    required
                    label="First Name"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4}>
                  <TextInputWrapper
                    name="lName"
                    label="Middle & Last Name"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4}>
                  <TextInputWrapper
                    required
                    name="phone"
                    label="Phone Number"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4}>
                  <TextInputWrapper
                    required
                    name="email"
                    label="Email Id"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4}>
                  <SelectWrapper
                    name="gender"
                    required
                    label="Gender"
                    options={genderOptions || []}
                    select
                    fullWidth
                  />
                </Grid>
                <Grid item md={4}>
                  <SelectWrapper
                    name="roleId"
                    required
                    label="Select Role"
                    options={roleOptions?.roles || []}
                    select
                    fullWidth
                  />
                </Grid>
                <Grid item md={4}>
                  <DateWrapper name="dob" label="DOB" disableFuture />
                </Grid>

                <Grid item md={8}>
                  <TextareaWrapper name="address" label="Address" />
                </Grid>
                <Grid item md={12}>
                  <CheckboxWrapper
                    name="isActivityLeader"
                    label1="Is Activity Leader?"
                    label2="Yes"
                  />
                </Grid>
                <Grid item md={8} sm={12} container>
                  <Grid item md={6} sm={5}>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                      <SubmitButton value={staffId ? "Save & Exit" : "Add"} />
                      {staffId && (
                        <LoadingButton
                          startIcon={<DeleteIcon />}
                          variant="contained"
                          size="large"
                          onClick={() => {
                            setIsDialogOpen(true);
                          }}
                        >
                          Delete
                        </LoadingButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Grid>
      );
    }
  };
  return <>{uiLoader()}</>;
};

export default AddStaff;
