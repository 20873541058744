import { Box } from "@mui/material";
import Header from "../../components/login/Header";
import LoginCard from "../../components/login/LoginCard";

const Login = () => {
  return (
    <Box sx={styles.outer}>
      <Header />
      <Box sx={styles.form}>
        <LoginCard />
      </Box>
    </Box>
  );
};

const styles = {
  outer: {
    display: "flex",
    gap: "3rem",
  },
  form: {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10rem",
    padding: "3rem",
  },
};

export default Login;
