import { Grid } from "@mui/material";
import SettingHeader from "../../components/commonHeader/SettingHeader";
import NoRecords from "../../components/norecords/NoRecords";
import Spinner from "../../components/loaders/Spinner";
import ListDelete from "../../components/lists/ListDelete";
import FloatingButton from "../../components/forms/FloatingButton";
import { useState } from "react";
import ModalSingleField from "../../components/modal/ModalSingleField";

import { useGetAllRolesQuery } from "../../apis/staff/staffApi";
import { useDeleteRoleMutation } from "../../apis/staff/staffApi";
import { useAddRoleMutation } from "../../apis/staff/staffApi";

const Setting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const hidden = true;

  const { data, isFetching, isLoading } = useGetAllRolesQuery(hidden);
  const [deleteRole] = useDeleteRoleMutation();
  const [addRole, { isLoading: isAddLoading }] = useAddRoleMutation();

  const uiLoader = () => {
    if (isFetching || isLoading) {
      return <Spinner />;
    } else if (data?.roles.length == 0) {
      return <NoRecords />;
    } else if (data?.roles.length > 0) {
      return data?.roles.map((el) => {
        return <ListDelete key={el._id} items={el} deleteFromDB={deleteRole} />;
      });
    }
  };
  return (
    <>
      <ModalSingleField
        label="Add Role"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isLoading={isAddLoading}
        addDataInDB={addRole}
      />
      <Grid item md={12}>
        <SettingHeader moduleName="Roles" />
      </Grid>

      <Grid item md={3}>
        {uiLoader()}
      </Grid>
      <Grid item md={12}>
        <FloatingButton buttonName="Add Role" setIsModalOpen={setIsModalOpen} />
      </Grid>
    </>
  );
};

export default Setting;
