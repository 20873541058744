import { Box, IconButton, List, ListItem, ListItemText } from "@mui/material";
import { DeleteIcon } from "../../assets/icons";

const ListDelete = ({ items, deleteFromDB }) => {
  const { _id, title } = items;
  const deleteHandler = () => {
    deleteFromDB(_id);
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <List>
        <ListItem disablePadding>
          <ListItemText primary={title} />
        </ListItem>
      </List>
      <IconButton onClick={deleteHandler}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default ListDelete;
