import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  deleteAdminFromLocalStorage,
  getTokenFromLocalStorage,
} from "../utils/localStorage";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://api-indiahome.incred.io/v1",
  prepareHeaders: (headers, { getState }) => {
    // Get the timezone using JavaScript on the client side
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const token = getState().auth.token || getTokenFromLocalStorage();
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
      headers.set('timezone',timezone)
    }

    return headers;
  },
});

const baseQueryWithAuth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  //console.log("baseApiresult--->", result?.error);
  if (result?.error?.status === 401) {
    api.dispatch(deleteAdminFromLocalStorage());
    window.location.href = "/login";
  }

  return result;
};

const baseApi = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({}),
});

export default baseApi;
