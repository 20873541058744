import baseApi from "../baseApi";

const logApi = baseApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getCommonLogs: builder.query({
        providesTags: ["Log"],
        query: ({ logFor, page, limit }) => {
          return {
            url: "/commonlogs",
            method: "GET",
            params: {
              logFor,
              page,
              limit,
            },
          };
        },
      }),
    };
  },
});

export const { useGetCommonLogsQuery } = logApi;
