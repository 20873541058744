import { createSlice } from "@reduxjs/toolkit";
import { addAdminToLocalStorage, addTokenToLocalStorage, deleteAdminFromLocalStorage } from "./../utils/localStorage";

const initialState = {
  token: "",
  admin: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAdmin: (state, { payload }) => {
      //console.log('from authSlice payload',payload)
      state.admin = payload;
      addAdminToLocalStorage(payload);
    },
    setToken: (state, { payload }) => {
      state.token = payload;
      addTokenToLocalStorage(payload);
    },
    logoutAdmin: (state) => {
      state.admin = "";
      state.token = "";
      deleteAdminFromLocalStorage();
    },
  },
});

export const { setAdmin, setToken, logoutAdmin } = authSlice.actions;
export default authSlice.reducer;
