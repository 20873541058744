import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useField, useFormikContext } from "formik";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

const TimeWrapper = ({ name, ...rest }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const config = {
    ...field,
    ...rest,
  };
  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {/* <TimeField
        sx={{ width: "100%" }}
        {...config}
        onChange={(newValue) => setFieldValue(name, newValue)}
      /> */}
      <MobileTimePicker
        {...config}
        onChange={(newValue) => setFieldValue(name, newValue)}
      />
    </LocalizationProvider>
  );
};

export default TimeWrapper;
