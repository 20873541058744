import { Grid, TextField, useTheme } from "@mui/material";
import {
  useGenerateOTPMutation,
  useVerifyOTPMutation,
} from "../../apis/auth/authApi";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setAdmin, setToken } from "../../features/authSlice";
import { useDispatch } from "react-redux";
import TimerContainer from "./TimerContainer";

const LoginForm = ({ loginMode, setIsAltOptionEnabled }) => {
  // mui theme access
  const theme = useTheme();
  // useNavigate hook
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // state for holding values entered by user
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [otpId, setOtpId] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [isTimerExpired, setIsTimerExpired] = useState(false);

  // state for holding Err state
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [otpCodeErr, setOtpCodeErr] = useState(false);

  // state for holding ErrMsg
  const [phoneErrMsg, setPhoneErrMsg] = useState("");
  const [emailErrMsg, setEmailErrMsg] = useState("");
  const [otpCodeErrMsg, setOtpCodeErrMsg] = useState("");
  // api need mode value
  let mode = "cms";

  // api to hit generateOTP
  const [httpGenerateOTP, { isLoading }] = useGenerateOTPMutation();
  // api to hit verifyOTP
  const [httpVerifyOTP, { isLoading: loading }] = useVerifyOTPMutation();

  const phoneChangeHandler = (e) => {
    setPhone(e.target.value);
    setPhoneError(false);
    setPhoneErrMsg("");
  };
  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
    setEmailErrMsg("");
  };
  const otpChangeHanlder = (e) => {
    setOtpCode(e.target.value);
    setOtpCodeErr(false);
    setOtpCodeErrMsg("");
  };
  const generateOTPHandler = async () => {
    if (loginMode && !phone) {
      setPhoneError(true);
      setPhoneErrMsg("Please Enter Phone Number");
    } else if (!email) {
      setEmailError(true);
      setEmailErrMsg("Please Enter Email Id");
    }
    // if there is value in phone or email then only hit API
    if (phone || email) {
      try {
        const resp = await httpGenerateOTP({ phone, email, mode }).unwrap();
        //console.log("api resp-->", resp);
        // if got otpId ,store it in otpId state
        if (resp?.otpId) {
          setOtpId(resp?.otpId);
          setIsTimerExpired(true);
        }

        // if otp is generated disable the other login option
        if (resp?.status === true) {
          setIsAltOptionEnabled(false);
        }
      } catch (err) {
        //console.log("LoginFormAPI Err-->", err);
        // if api gives error then setting state
        if (err?.data) {
          if (loginMode) {
            setPhoneError(true);
            setPhoneErrMsg(err?.data.msg);
          } else {
            setEmailError(true);
            setEmailErrMsg(err?.data?.msg);
          }
        }
      }
    }
  };

  const verfiyOTPHandler = async () => {
    if (!otpCode) {
      setOtpCodeErr(true);
      setOtpCodeErrMsg("Please Enter 6 digit OTP Code");
    }
    // hit Api only if otpCode & otpId have values set
    if (otpCode && otpId) {
      try {
        const resp = await httpVerifyOTP({ otpId, otpCode }).unwrap();
        //console.log("verifyOTP Api resp-->", resp);
        dispatch(setAdmin(resp?.user));
        dispatch(setToken(resp?.token));
        navigate("/");
      } catch (err) {
        //console.log("ValidateOTP ApiErr", err);
        if (err?.data) {
          setOtpCodeErr(true);
          setOtpCodeErrMsg(err?.data?.msg);
        }
      }
    }
  };

  return (
    <Grid container row spacing={3}>
      <Grid item md={8} sm={6}>
        {loginMode === true ? (
          <TextField
            name="phone"
            type="number"
            value={phone}
            label="Phone Number"
            fullWidth
            required
            size="small"
            error={phoneError}
            helperText={phoneErrMsg}
            onChange={(e) => {
              phoneChangeHandler(e);
            }}
          />
        ) : (
          <TextField
            name="email"
            type="email"
            value={email}
            label="Email ID"
            fullWidth
            required
            size="small"
            error={emailError}
            helperText={emailErrMsg}
            onChange={(e) => {
              emailChangeHandler(e);
            }}
          />
        )}
      </Grid>
      <Grid item md={4} sm={6}>
        <LoadingButton
          variant="outlined"
          size="medium"
          loading={isLoading}
          disabled={!!isTimerExpired}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            width: "90%",

            "&:hover": {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
            },
          }}
          onClick={generateOTPHandler}
        >
          GENERATE
        </LoadingButton>
      </Grid>
      {isTimerExpired && (
        <>
          <Grid item md={8} sm={6}>
            <TextField
              name="otp"
              label="Enter OTP"
              type="number"
              value={otpCode}
              fullWidth
              error={otpCodeErr}
              helperText={otpCodeErrMsg}
              required
              size="small"
              onChange={(e) => {
                otpChangeHanlder(e);
              }}
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <LoadingButton
              variant="outlined"
              size="medium"
              loading={loading}
              onClick={verfiyOTPHandler}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                width: "90%",

                "&:hover": {
                  backgroundColor: theme.palette.common.black,
                  color: theme.palette.common.white,
                },
              }}
            >
              VALIDATE
            </LoadingButton>
          </Grid>
          <Grid item md={12}>
            <TimerContainer
              setIsTimerExpired={setIsTimerExpired}
              setIsAltOptionEnabled={setIsAltOptionEnabled}
              setOtpCodeErr={setOtpCodeErr}
              setOtpCodeErrMsg={setOtpCodeErrMsg}
              setOtpId={setOtpId}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LoginForm;
