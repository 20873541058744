import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const Calendar = ({ label, setFieldValue, setIsDisable }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        disableFuture={true}
        sx={{
          width: "100%",
        }}
        format="MM/DD/YYYY"
        renderInput={(params) => {
          return <TextField {...params} />;
        }}
        onChange={(newValue) => {
            setIsDisable(false)
            setFieldValue(newValue)
        }}
      />
    </LocalizationProvider>
  );
};

export default Calendar;
