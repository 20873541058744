import baseApi from "../baseApi";

const masterApi = baseApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getAllMasterEvent: builder.query({
        providesTags: ["Master"],
        query: ({ page }) => {
          return {
            url: "/eventMaster",
            method: "GET",
            params: {
              page,
            },
          };
        },
      }),
      getAllEventCat: builder.query({
        query: () => {
          return {
            url: "/eventCat",
            method: "GET",
          };
        },
        transformResponse: (response) => {
          //console.log("before transformation-->", response?.catsToShow);
          return response?.catsToShow;
        },
      }),
      getAllEventLocations: builder.query({
        query: () => {
          return {
            url: "/locationEvents",
            method: "GET",
          };
        },
        transformResponse: (response) => {
          return response?.locations;
        },
      }),
      getAllActivityLeader: builder.query({
        query: () => {
          return {
            url: "/staffs",
            method: "GET",
            params: {
              page: 1,
              limit: 40,
              isActivityLeader: true,
            },
          };
        },
        transformResponse: (response) => {
          const activityLeader = [];
          if (response?.staffs) {
            response?.staffs.map((el) => {
              activityLeader.push({
                _id: el?._id,
                fName: el?.fName,
                lName: el?.lName,
                profile: el?.profile,
              });
            });
          }
          return activityLeader;
        },
      }),
      addMasterEvent: builder.mutation({
        invalidatesTags: ["Master"],
        query: (masterData) => {
          return {
            url: "/eventMaster",
            method: "POST",
            body: masterData,
          };
        },
      }),
      getMasterEventById: builder.query({
        query: (masterId) => {
          return {
            url: `/eventMaster/${masterId}`,
            method: "GET",
          };
        },
      }),
      deleteMasterEvent: builder.mutation({
        invalidatesTags: ["Master"],
        query: (eventId) => {
          return {
            url: `/eventMaster/${eventId}`,
            method: "DELETE",
          };
        },
      }),
      editMasterEvent: builder.mutation({
        invalidatesTags: ["Master"],
        query: ({ masterId, ...masterData }) => {
          return {
            url: `/eventMaster/${masterId}`,
            method: "PATCH",
            body: masterData,
          };
        },
      }),
      publishMasterEvent: builder.mutation({
        invalidatesTags: ["Session"],
        query: (publishDate) => {
          return {
            url: "/publishEvent",
            method: "POST",
            body: {
              publishDate: publishDate,
            },
          };
        },
      }),
    };
  },
});

export const {
  useGetAllMasterEventQuery,
  useGetAllEventCatQuery,
  useGetAllEventLocationsQuery,
  useGetAllActivityLeaderQuery,
  useAddMasterEventMutation,
  useLazyGetMasterEventByIdQuery,
  useDeleteMasterEventMutation,
  useEditMasterEventMutation,
  usePublishMasterEventMutation,
} = masterApi;
