import ClearIcon from "@mui/icons-material/Clear";
import CropIcon from "@mui/icons-material/Crop";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import AWS from "aws-sdk";
import { useRef, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import ReactCrop, {
  centerCrop,
  convertToPercentCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const s3 = new AWS.S3({
  accessKeyId: "AKIA3UWGR5QW2BYVSUSL",
  secretAccessKey: "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",
  region: "us-east-1",
});
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  const crop = makeAspectCrop(
    {
      unit: "%",
      width: 90,
    },
    aspect,
    mediaWidth,
    mediaHeight
  );
  return centerCrop(crop, mediaWidth, mediaHeight);
}
const Cropper = ({
  isCropperOpen,
  setIsCropperOpen,
  imageSrc,
  setProfilePic,
  aspectType,
  setIsImageUploading,
}) => {
  const previewCanvasRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();

  let aspect = aspectType === 1 ? 3 / 4 : 16 / 9;
  const [completedCrop, setCompletedCrop] = useState();

  const cropImageNow = async () => {
    setIsCropperOpen(false);
    setIsImageUploading(true);
    if (
      completedCrop &&
      imgRef.current &&
      completedCrop?.width &&
      completedCrop?.height
    ) {
      var canvas = document.createElement("canvas");

      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("No 2d context");
      }

      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      // devicePixelRatio slightly increases sharpness on retina devices
      // at the expense of slightly slower render times and needing to
      // size the image back down if you want to download/upload and be
      // true to the images natural size.
      const pixelRatio = window.devicePixelRatio;
      // const pixelRatio = 1

      //console.log("dwqdqwdwd", crop.width, crop.height);

      canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
      canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = "high";

      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;

      const centerX = imgRef.current.naturalWidth / 2;
      const centerY = imgRef.current.naturalHeight / 2;

      ctx.save();

      // 5) Move the crop origin to the canvas origin (0,0)
      ctx.translate(-cropX, -cropY);
      // 4) Move the origin to the center of the original position
      ctx.translate(centerX, centerY);
      // 3) Rotate around the origin

      // 1) Move the center of the image to the origin (0,0)
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        imgRef.current,
        0,
        0,
        imgRef.current.naturalWidth,
        imgRef.current.naturalHeight,
        0,
        0,
        imgRef.current.naturalWidth,
        imgRef.current.naturalHeight
      );

      const croppedImageData = canvas.toDataURL("image/jpeg");
      //console.log(croppedImageData);
      // Convert the data URL to a Blob
      canvas.toBlob(async (blob) => {
        // Handle the Blob here
        //console.log("blob", blob);
        try {
          const { Location } = await s3
            .upload({
              Bucket: "indiahomesbucket",
              Key: `images/${Date.now()}.jpeg`,
              ContentType: blob.type,
              Body: blob,
            })
            .promise();
          console.log(`File uploaded successfully to ${Location}`);
          setProfilePic(Location);
          setIsUploading(false);
          setIsCropperOpen(false);
        } catch (error) {
          setIsUploading(false);
          console.error("Error uploading file:", error);
        }
      }, "image/jpeg");
      ctx.restore();
    }
  };

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width - 1, height - 1, aspect));
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={isCropperOpen}
      onClose={() => {
        setIsCropperOpen(false);
      }}
    >
      <DialogTitle>Image Cropper</DialogTitle>
      <DialogContent>
        {imageSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => {
              setCrop(_);
              // console.log("dqwddwqd",_)
            }}
            onComplete={(c) => {
              setCompletedCrop(c);
            }}
            aspect={aspect}
            // minWidth={400}
            minHeight={200}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imageSrc}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}

        {/* {completedCrop && (
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        )} */}
        {/* <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => {
            setCompletedCrop(c);
          }}
          keepSelection={true}
          aspect={aspectType === 1 ? 3 / 4 : 16 / 9}
          // locked={true}
        >
          <img ref={imgRef} alt="Crop me" src={imageSrc} onLoad={onImageLoad} />
        </ReactCrop> */}
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<ClearIcon />}
          variant="contained"
          onClick={() => {
            setIsCropperOpen(false);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          startIcon={<CropIcon />}
          variant="contained"
          loading={isUploading}
          onClick={cropImageNow}
        >
          Crop
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default Cropper;
